import actionTypes from "./actions.js";
import mutationTypes from "./mutations.js";
import httpClient from "@/libs/axios.js";
import router from "@/router/index.js";

const state = {
  teamMembers: [],
};

const mutations = {
  [mutationTypes.SET_TEAM_MEMBERS](state, teamMembers) {
    state.teamMembers = teamMembers;
  },
};

const actions = {
  async [actionTypes.GET_ALL_TEAM_MEMBERS]({ commit }) {
    const { data: result } = await httpClient.get("/team-member");
    if (result.success) {
      commit(mutationTypes.SET_TEAM_MEMBERS, result.data);
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.GET_TEAM_MEMBER_BY_ID]({ commit }, id) {
    const { data: result } = await httpClient.get(`/team-member/${id}`);
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.ADD_OR_UPDATE_TEAM_MEMBER]({ commit }, teamMember) {
    const { data: result } = await httpClient.post("/team-member", teamMember);
    if (result.success) {
      router.push({ name: "TeamMembers" });
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.DELETE_TEAM_MEMBER]({ commit }, { id, redirectTo }) {
    await httpClient.post(`/team-member/delete/${id}`, { redirectTo });
  },
};

const getters = {
  teamMembers: (state) => state.teamMembers,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
