<template>
  <Page>
    <form @submit.prevent="handleSubmit" ref="form">
      <PageCard title="Update Home Page Content">
        <div class="row">
          <div class="form-group col-12">
            <label>Tab Title</label>
            <input
              v-model="pageContent.tabTitle"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.tabTitle.$error }"
            />
            <div v-if="submitted && $v.pageContent.tabTitle.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.tabTitle.required">This field is required</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12">
            <label>Section-1 Title (Header)</label>
            <input
              v-model="pageContent.section1Title"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section1Title.$error }"
            />
            <div v-if="submitted && $v.pageContent.section1Title.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section1Title.required">This field is required</span>
            </div>
          </div>
        </div>
      </PageCard>
      <PageCard title="Section-2">
        <div class="row">
          <div class="form-group col-md-6">
            <label>Left</label>
            <input
              v-model="pageContent.section2Left"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section2Left.$error }"
            />
            <div v-if="submitted && $v.pageContent.section2Left.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section2Left.required">This field is required</span>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Right</label>
            <input
              v-model="pageContent.section2Right"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section2Right.$error }"
            />
            <div v-if="submitted && $v.pageContent.section2Right.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section2Right.required">This field is required</span>
            </div>
          </div>
        </div>
      </PageCard>
      <PageCard title="Section-3">
        <div class="row">
          <div class="form-group col-md-6">
            <label>Title</label>
            <input
              v-model="pageContent.section3Title"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section3Title.$error }"
            />
            <div v-if="submitted && $v.pageContent.section3Title.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section3Title.required">This field is required</span>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Content</label>
            <input
              v-model="pageContent.section3Content"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section3Content.$error }"
            />
            <div v-if="submitted && $v.pageContent.section3Content.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section3Content.required">This field is required</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label>Left Key</label>
            <input
              v-model="pageContent.section3LeftKey"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section3LeftKey.$error }"
            />
            <div v-if="submitted && $v.pageContent.section3LeftKey.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section3LeftKey.required">This field is required</span>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Left Value</label>
            <input
              v-model="pageContent.section3LeftValue"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section3LeftValue.$error }"
            />
            <div v-if="submitted && $v.pageContent.section3LeftValue.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section3LeftValue.required">This field is required</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label>Right Key</label>
            <input
              v-model="pageContent.section3RightKey"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section3RightKey.$error }"
            />
            <div v-if="submitted && $v.pageContent.section3RightKey.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section3RightKey.required">This field is required</span>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Right Value</label>
            <input
              v-model="pageContent.section3RightValue"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section3RightValue.$error }"
            />
            <div v-if="submitted && $v.pageContent.section3RightValue.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section3RightValue.required">This field is required</span>
            </div>
          </div>
        </div>
      </PageCard>
      <PageCard title="Section-4">
        <div class="row">
          <div class="form-group col-md-6">
            <label>Title</label>
            <input
              v-model="pageContent.section4Title"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section4Title.$error }"
            />
            <div v-if="submitted && $v.pageContent.section4Title.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section4Title.required">This field is required</span>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Content</label>
            <input
              v-model="pageContent.section4Content"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section4Content.$error }"
            />
            <div v-if="submitted && $v.pageContent.section4Content.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section4Content.required">This field is required</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="dropzone" id="section4FirstImageDropzone" ref="section4FirstImageDropzone"></div>
            <div v-if="submitted && $v.pageContent.section4FirstImage.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section4FirstImage.required">Image is required</span>
            </div>
            <div class="form-group">
              <label>First Title</label>
              <input
                v-model="pageContent.section4FirstTitle"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.pageContent.section4FirstTitle.$error }"
              />
              <div v-if="submitted && $v.pageContent.section4FirstTitle" class="invalid-feedback">
                <span v-if="!$v.pageContent.section4FirstTitle.required">This field is required</span>
              </div>
            </div>
            <div class="form-group">
              <label>First Content</label>
              <input
                v-model="pageContent.section4FirstContent"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.pageContent.section4FirstContent.$error }"
              />
              <div v-if="submitted && $v.pageContent.section4FirstContent" class="invalid-feedback">
                <span v-if="!$v.pageContent.section4FirstContent.required">This field is required</span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="dropzone" id="section4SecondImageDropzone" ref="section4SecondImageDropzone"></div>
            <div v-if="submitted && $v.pageContent.section4SecondImage.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section4SecondImage.required">Image is required</span>
            </div>
            <div class="form-group">
              <label>Secont Title</label>
              <input
                v-model="pageContent.section4SecondTitle"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.pageContent.section4SecondTitle.$error }"
              />
              <div v-if="submitted && $v.pageContent.section4SecondTitle" class="invalid-feedback">
                <span v-if="!$v.pageContent.section4SecondTitle.required">This field is required</span>
              </div>
            </div>
            <div class="form-group">
              <label>Secont Content</label>
              <input
                v-model="pageContent.section4SecondContent"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.pageContent.section4SecondContent.$error }"
              />
              <div v-if="submitted && $v.pageContent.section4SecondContent" class="invalid-feedback">
                <span v-if="!$v.pageContent.section4SecondContent.required">This field is required</span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="dropzone" id="section4ThirdImageDropzone" ref="section4ThirdImageDropzone"></div>
            <div v-if="submitted && $v.pageContent.section4ThirdImage.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section4ThirdImage.required">Image is required</span>
            </div>
            <div class="form-group">
              <label>Third Title</label>
              <input
                v-model="pageContent.section4ThirdTitle"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.pageContent.section4ThirdTitle.$error }"
              />
              <div v-if="submitted && $v.pageContent.section4ThirdTitle" class="invalid-feedback">
                <span v-if="!$v.pageContent.section4ThirdTitle.required">This field is required</span>
              </div>
            </div>
            <div class="form-group">
              <label>Third Content</label>
              <input
                v-model="pageContent.section4ThirdContent"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.pageContent.section4ThirdContent.$error }"
              />
              <div v-if="submitted && $v.pageContent.section4ThirdContent" class="invalid-feedback">
                <span v-if="!$v.pageContent.section4ThirdContent.required">This field is required</span>
              </div>
            </div>
          </div>
        </div>
      </PageCard>
      <PageCard title="Section-5">
        <div class="row">
          <div class="form-group col-md-6">
            <label>Title</label>
            <input
              v-model="pageContent.section5Title"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section5Title.$error }"
            />
            <div v-if="submitted && $v.pageContent.section5Title.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section5Title.required">This field is required</span>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Content</label>
            <input
              v-model="pageContent.section5Content"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section5Content.$error }"
            />
            <div v-if="submitted && $v.pageContent.section5Content.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section5Content.required">This field is required</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="dropzone" id="section5FirstImageDropzone" ref="section5FirstImageDropzone"></div>
            <div v-if="submitted && $v.pageContent.section5FirstImage.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section5FirstImage.required">Image is required</span>
            </div>
            <div class="form-group">
              <label>First Title</label>
              <input
                v-model="pageContent.section5FirstTitle"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.pageContent.section5FirstTitle.$error }"
              />
              <div v-if="submitted && $v.pageContent.section5FirstTitle.$error" class="invalid-feedback">
                <span v-if="!$v.pageContent.section5FirstTitle.required">This field is required</span>
              </div>
            </div>
            <div class="form-group">
              <label>First Content</label>
              <input
                v-model="pageContent.section5FirstContent"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.pageContent.section5FirstContent.$error }"
              />
              <div v-if="submitted && $v.pageContent.section5FirstContent.$error" class="invalid-feedback">
                <span v-if="!$v.pageContent.section5FirstContent.required">This field is required</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="dropzone" id="section5SecondImageDropzone" ref="section5SecondImageDropzone"></div>
            <div v-if="submitted && $v.pageContent.section5SecondImage.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section5SecondImage.required">Image is required</span>
            </div>
            <div class="form-group">
              <label>Second Title</label>
              <input
                v-model="pageContent.section5SecondTitle"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.pageContent.section5SecondTitle.$error }"
              />
              <div v-if="submitted && $v.pageContent.section5SecondTitle.$error" class="invalid-feedback">
                <span v-if="!$v.pageContent.section5SecondTitle.required">This field is required</span>
              </div>
            </div>
            <div class="form-group">
              <label>Second Content</label>
              <input
                v-model="pageContent.section5SecondContent"
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.pageContent.section5SecondContent.$error }"
              />
              <div v-if="submitted && $v.pageContent.section5SecondContent.$error" class="invalid-feedback">
                <span v-if="!$v.pageContent.section5SecondContent.required">This field is required</span>
              </div>
            </div>
          </div>
        </div>
      </PageCard>
      <PageCard title="Section-6">
        <div class="row">
          <div class="form-group col-md-6">
            <label>Title</label>
            <input
              v-model="pageContent.section6Title"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section6Title.$error }"
            />
            <div v-if="submitted && $v.pageContent.section6Title.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section6Title.required">This field is required</span>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Content</label>
            <input
              v-model="pageContent.section6Content"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section6Content.$error }"
            />
            <div v-if="submitted && $v.pageContent.section6Content.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section6Content.required">This field is required</span>
            </div>
          </div>
        </div>
      </PageCard>
      <PageCard title="Meta Tags">
        <div class="row mb-3">
          <div class="col-lg-12 text-right">
            <button v-if="pageContent.metaTags.length" type="button" class="btn btn-danger mr-2" @click.prevent="removeAllMetaTags">
              <i class="icon-trash mr-1"></i>
              Remove All
            </button>
            <button type="button" class="btn btn-primary" @click.prevent="addNewMetaTag">
              <i class="icon-plus3 mr-1"></i>
              Add
            </button>
          </div>
        </div>

        <div class="row" v-for="(tag, index) in pageContent.metaTags" :key="index">
          <div class="form-group col-lg-5">
            <label>Name</label>
            <input v-model="tag.name" class="form-control" />
          </div>
          <div class="form-group col-lg-6">
            <label>Content</label>
            <input v-model="tag.content" class="form-control" />
          </div>
          <div class="form-group col-lg-1">
            <label>Actions</label>
            <button type="button" class="btn btn-block btn-danger" @click.prevent="removeMetaTag(index)">
              <i class="icon-trash mr-1"></i>
            </button>
          </div>
        </div>
      </PageCard>
      <PageCard title="Additional JavaScript">
        <div class="row">
          <div class="form-group col-12">
            <textarea
              v-model="pageContent.additionalJavascript"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.additionalJavascript.$error }"
            ></textarea>
            <div v-if="submitted && $v.pageContent.additionalJavascript.$error" class="invalid-feedback"></div>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button type="button" class="btn btn-primary mr-2" @click="handlePreview">Preview</button>
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </PageCard>
    </form>
  </Page>
</template>

<script>
import pageContentActions from "@/store/pageContent/actions.js";
import { required } from "vuelidate/lib/validators";
import Dropzone from "dropzone";

let formLoader = null;
let section4FirstImageLoader = null;
let section4SecondImageLoader = null;
let section4ThirdImageLoader = null;
let section5FirstImageLoader = null;
let section5SecondImageLoader = null;

export default {
  name: "UpdateHomePageContent",

  data() {
    return {
      pageContent: {
        tabTitle: "",
        section1Title: "",
        section2Left: "",
        section2Right: "",
        section3Title: "",
        section3Content: "",
        section3LeftKey: "",
        section3LeftValue: "",
        section3RightKey: "",
        section3RightValue: "",
        section4Title: "",
        section4Content: "",
        section4FirstTitle: "",
        section4FirstContent: "",
        section4FirstImage: "",
        section4SecondTitle: "",
        section4SecondContent: "",
        section4SecondImage: "",
        section4ThirdTitle: "",
        section4ThirdContent: "",
        section4ThirdImage: "",
        section5Title: "",
        section5Content: "",
        section5FirstTitle: "",
        section5FirstContent: "",
        section5FirstImage: "",
        section5SecondTitle: "",
        section5SecondContent: "",
        section5SecondImage: "",
        section6Title: "",
        section6Content: "",
        additionalJavascript: "",
        metaTags: [],
      },

      submitted: false,
    };
  },

  async mounted() {
    formLoader = this.$loading.show({ container: this.$refs.form });
    const { pageContent, metaTags, scripts } = await this.$store.dispatch(pageContentActions.GET_HOME_PAGE_CONTENT);
    this.pageContent = { ...pageContent, metaTags, additionalJavascript: scripts.content };

    this.$nextTick(() => {
      this.initDropzone();
      formLoader.hide();
    });
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        formLoader.hide();
        return;
      }

      await this.$store.dispatch(pageContentActions.UPDATE_HOME_PAGE_CONTENT, this.pageContent);
      formLoader.hide();
    },
    initDropzone() {
      let section4FirstImageDropzone = new Dropzone("#section4FirstImageDropzone", {
        url: `${process.env.VUE_APP_BASE_API_URL}/general/upload-file?uploadType=page-content`,
        maxFiles: 1,
        acceptedFiles: "image/*",
        dictDefaultMessage: "",
        addRemoveLinks: true,
        headers: {
          authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        withCredentials: true,
      });

      section4FirstImageDropzone.on("addedfile", () => {
        section4FirstImageLoader = this.$loading.show({ container: this.$refs.section4FirstImageDropzone });
      });

      section4FirstImageDropzone.on("success", (file, response) => {
        this.pageContent.section4FirstImage = response.data;
      });

      section4FirstImageDropzone.on("removedfile", () => {
        this.pageContent.section4FirstImage = "";
      });

      section4FirstImageDropzone.on("complete", () => {
        section4FirstImageLoader.hide();
      });

      let section4SecondImageDropzone = new Dropzone("#section4SecondImageDropzone", {
        url: `${process.env.VUE_APP_BASE_API_URL}/general/upload-file?uploadType=page-content`,
        maxFiles: 1,
        acceptedFiles: "image/*",
        dictDefaultMessage: "",
        addRemoveLinks: true,
        headers: {
          authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        withCredentials: true,
      });

      section4SecondImageDropzone.on("addedfile", () => {
        section4SecondImageLoader = this.$loading.show({ container: this.$refs.section4SecondImageDropzone });
      });

      section4SecondImageDropzone.on("success", (file, response) => {
        this.pageContent.section4SecondImage = response.data;
      });

      section4SecondImageDropzone.on("removedfile", () => {
        this.pageContent.section4SecondImage = "";
      });

      section4SecondImageDropzone.on("complete", () => {
        section4SecondImageLoader.hide();
      });

      let section4ThirdImageDropzone = new Dropzone("#section4ThirdImageDropzone", {
        url: `${process.env.VUE_APP_BASE_API_URL}/general/upload-file?uploadType=page-content`,
        maxFiles: 1,
        acceptedFiles: "image/*",
        dictDefaultMessage: "",
        addRemoveLinks: true,
        headers: {
          authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        withCredentials: true,
      });

      section4ThirdImageDropzone.on("addedfile", () => {
        section4ThirdImageLoader = this.$loading.show({ container: this.$refs.section4ThirdImageDropzone });
      });

      section4ThirdImageDropzone.on("success", (file, response) => {
        this.pageContent.section4ThirdImage = response.data;
      });

      section4ThirdImageDropzone.on("removedfile", () => {
        this.pageContent.section4ThirdImage = "";
      });

      section4ThirdImageDropzone.on("complete", () => {
        section4ThirdImageLoader.hide();
      });

      let section5FirstImageDropzone = new Dropzone("#section5FirstImageDropzone", {
        url: `${process.env.VUE_APP_BASE_API_URL}/general/upload-file?uploadType=page-content`,
        maxFiles: 1,
        acceptedFiles: "image/*",
        dictDefaultMessage: "",
        addRemoveLinks: true,
        headers: {
          authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        withCredentials: true,
      });

      section5FirstImageDropzone.on("addedfile", () => {
        section5FirstImageLoader = this.$loading.show({ container: this.$refs.section5FirstImageDropzone });
      });

      section5FirstImageDropzone.on("success", (file, response) => {
        this.pageContent.section5FirstImage = response.data;
      });

      section5FirstImageDropzone.on("removedfile", () => {
        this.pageContent.section5FirstImage = "";
      });

      section5FirstImageDropzone.on("complete", () => {
        section5FirstImageLoader.hide();
      });

      let section5SecondImageDropzone = new Dropzone("#section5SecondImageDropzone", {
        url: `${process.env.VUE_APP_BASE_API_URL}/general/upload-file?uploadType=page-content`,
        maxFiles: 1,
        acceptedFiles: "image/*",
        dictDefaultMessage: "",
        addRemoveLinks: true,
        headers: {
          authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        withCredentials: true,
      });

      section5SecondImageDropzone.on("addedfile", () => {
        section5SecondImageLoader = this.$loading.show({ container: this.$refs.section5SecondImageDropzone });
      });

      section5SecondImageDropzone.on("success", (file, response) => {
        this.pageContent.section5SecondImage = response.data;
      });

      section5SecondImageDropzone.on("removedfile", () => {
        this.pageContent.section5SecondImage = "";
      });

      section5SecondImageDropzone.on("complete", () => {
        section5SecondImageLoader.hide();
      });

      const sectinon4FirstImageMockFile = { name: "", dataURL: this.pageContent.section4FirstImage, size: 12345, type: "image/jpeg" };

      section4FirstImageDropzone.emit("addedfile", sectinon4FirstImageMockFile);
      section4FirstImageDropzone.emit("success", sectinon4FirstImageMockFile, { data: this.pageContent.section4FirstImage });
      section4FirstImageDropzone.emit("thumbnail", sectinon4FirstImageMockFile, this.pageContent.section4FirstImage);
      section4FirstImageDropzone.emit("complete", sectinon4FirstImageMockFile);

      const sectinon4SecondImageMockFile = { name: "", dataURL: this.pageContent.section4SecondImage, size: 12345, type: "image/jpeg" };

      section4SecondImageDropzone.emit("addedfile", sectinon4SecondImageMockFile);
      section4SecondImageDropzone.emit("success", sectinon4SecondImageMockFile, { data: this.pageContent.section4SecondImage });
      section4SecondImageDropzone.emit("thumbnail", sectinon4SecondImageMockFile, this.pageContent.section4SecondImage);
      section4SecondImageDropzone.emit("complete", sectinon4SecondImageMockFile);

      const sectinon4ThirdImageMockFile = { name: "", dataURL: this.pageContent.section4ThirdImage, size: 12345, type: "image/jpeg" };

      section4ThirdImageDropzone.emit("addedfile", sectinon4ThirdImageMockFile);
      section4ThirdImageDropzone.emit("success", sectinon4ThirdImageMockFile, { data: this.pageContent.section4ThirdImage });
      section4ThirdImageDropzone.emit("thumbnail", sectinon4ThirdImageMockFile, this.pageContent.section4ThirdImage);
      section4ThirdImageDropzone.emit("complete", sectinon4ThirdImageMockFile);

      const sectinon5FirstImageMockFile = { name: "", dataURL: this.pageContent.section5FirstImage, size: 12345, type: "image/jpeg" };

      section5FirstImageDropzone.emit("addedfile", sectinon5FirstImageMockFile);
      section5FirstImageDropzone.emit("success", sectinon5FirstImageMockFile, { data: this.pageContent.section5FirstImage });
      section5FirstImageDropzone.emit("thumbnail", sectinon5FirstImageMockFile, this.pageContent.section5FirstImage);
      section5FirstImageDropzone.emit("complete", sectinon5FirstImageMockFile);

      const sectinon5SecondImageMockFile = { name: "", dataURL: this.pageContent.section5SecondImage, size: 12345, type: "image/jpeg" };

      section5SecondImageDropzone.emit("addedfile", sectinon5SecondImageMockFile);
      section5SecondImageDropzone.emit("success", sectinon5SecondImageMockFile, { data: this.pageContent.section5SecondImage });
      section5SecondImageDropzone.emit("thumbnail", sectinon5SecondImageMockFile, this.pageContent.section5SecondImage);
      section5SecondImageDropzone.emit("complete", sectinon5SecondImageMockFile);
    },
    removeAllMetaTags() {
      this.pageContent.metaTags = [];
    },
    addNewMetaTag() {
      this.pageContent.metaTags.push({
        name: "",
        content: "",
      });
    },
    removeMetaTag(index) {
      this.pageContent.metaTags.splice(index, 1);
    },
    handlePreview() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$message.error("Please fill all required fields");
        return;
      }

      var params = this.pageContent;
      params.page = "home";
      const paramsObj = new URLSearchParams(params).toString();
      window.open(`/preview?${paramsObj.toString()}`, "", "width=1000,height=800");
    },
  },

  validations() {
    return {
      pageContent: {
        tabTitle: { required },
        section1Title: { required },
        section2Left: { required },
        section2Right: { required },
        section3Title: { required },
        section3Content: { required },
        section3LeftKey: { required },
        section3LeftValue: { required },
        section3RightKey: { required },
        section3RightValue: { required },
        section4Title: { required },
        section4Content: { required },
        section4FirstTitle: { required },
        section4FirstContent: { required },
        section4FirstImage: { required },
        section4SecondTitle: { required },
        section4SecondContent: { required },
        section4SecondImage: { required },
        section4ThirdTitle: { required },
        section4ThirdContent: { required },
        section4ThirdImage: { required },
        section5Title: { required },
        section5Content: { required },
        section5FirstTitle: { required },
        section5FirstContent: { required },
        section5FirstImage: { required },
        section5SecondTitle: { required },
        section5SecondContent: { required },
        section5SecondImage: { required },
        section6Title: { required },
        section6Content: { required },
        additionalJavascript: {},
      },
    };
  },

  metaInfo() {
    return {
      title: "Update Home Page Content",
    };
  },
};
</script>
