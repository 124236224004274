export default {
  GET_ALL_REDIRECTS: "GET_ALL_REDIRECTS",
  GET_REDIRECT_BY_ID: "GET_REDIRECT_BY_ID",
  ADD_OR_UPDATE_REDIRECT: "ADD_OR_UPDATE_REDIRECT",
  DELETE_REDIRECT: "DELETE_REDIRECT",
  GET_ROBOTS_TXT: "GET_ROBOTS_TXT",
  UPDATE_ROBOTS_TXT: "UPDATE_ROBOTS_TXT",
  GET_SITEMAP: "GET_SITEMAP",
  UPDATE_SITEMAP: "UPDATE_SITEMAP",
};
