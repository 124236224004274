<template>
  <div>
    <HeaderMenu :links="links" />
    <div class="page-content pt-0">
      <Sidenav />
      <div class="content-wrapper">
        <div class="content">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidenav from "@/components/common/sidenav/Sidenav.vue";

export default {
  name: "Page",

  components: {
    Sidenav,
  },

  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
