<template>
  <Page>
    <PageCard title="Contact">
      <el-table :data="paginatedData" ref="table">
        <el-table-column prop="firstName" label="Full Name">
          <template slot-scope="scope">
            <span>{{ scope.row.firstName }} {{ scope.row.lastName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="email" label="Email" />
        <el-table-column prop="phone" label="Phone" />
        <el-table-column prop="contactType" label="Category">
          <template slot-scope="scope">
            <span>{{ contactTypes.find((x) => x.value === scope.row.contactType).displayName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="isRead" label="Read" align="center" width="100px" sortable>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isRead" type="success">Read</el-tag>
            <el-tag v-else type="danger">Unread</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="Created Date" sortable width="150px" align="center">
          <template slot-scope="scope">
            {{ $formatDate(scope.row.createdAt) }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="220px">
          <template slot="header">
            <input class="form-control" v-model="searchTerm" placeholder="Type to search" />
          </template>
          <template slot-scope="scope">
            <el-button @click.prevent="handleEdit(scope.row)" icon="icon-pencil" size="mini" class="mr-2" />
            <el-button @click.prevent="handleDelete(scope.row)" icon="icon-trash" size="mini" type="danger" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="d-flex justify-content-center mt-3"
        :current-page="currentPage"
        :page-size="pageSize"
        @current-change="(val) => (currentPage = val)"
        @size-change="(val) => (pageSize = val)"
        :page-sizes="[5, 15, 30, 50, 100]"
        background
        layout="sizes,prev,pager,next"
        :total="filteredData.length"
      />
    </PageCard>
  </Page>
</template>

<script>
import contactActions from "@/store/contact/actions.js";
import { mapGetters } from "vuex";

let tableLoader = null;

export default {
  name: "Contacts",

  data() {
    return {
      contacts: [],
      searchTerm: "",
      currentPage: 1,
      pageSize: 15,
    };
  },

  async mounted() {
    tableLoader = this.$loading.show({ container: this.$refs.table.$el });
    this.contacts = await this.$store.dispatch(contactActions.GET_ALL_CONTACTS);
    tableLoader.hide();
  },

  methods: {
    handleEdit(contact) {
      this.$router.push({ name: "EditContact", params: { id: contact.id } });
    },

    handleDelete(contact) {
      this.$confirm("Are you sure to delete this record?", async () => {
        tableLoader = this.$loading.show({ container: this.$refs.table.$el });
        await this.$store.dispatch(contactActions.DELETE_CONTACT, contact.id);
        this.contacts.splice(this.contacts.indexOf(contact), 1);
        tableLoader.hide();
      });
    },
  },

  computed: {
    filteredData() {
      return this.contacts.filter(
        (x) =>
          !this.searchTerm ||
          x.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          x.lastName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          x.message.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          x.email.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          x.phone.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          this.contactTypes
            .find((y) => y.value === x.contactType)
            .displayName.toLowerCase()
            .includes(this.searchTerm.toLowerCase())
      );
    },
    paginatedData() {
      return this.filteredData.slice(this.pageSize * this.currentPage - this.pageSize, this.pageSize * this.currentPage);
    },
    ...mapGetters(["contactTypes"]),
  },

  metaInfo() {
    return {
      title: "Contacts",
    };
  },
};
</script>
