<template>
  <li class="nav-item">
    <router-link v-if="!blank" :to="{ name: to }" class="nav-link" :class="{ active: isActive }" :blank="blank">
      <i :class="icon" data-popup="tooltip" :title="text"></i>
      <span>{{ text }}</span>
      <span v-if="badge" class="badge badge-success text-white ml-2">{{ badge }}</span>
    </router-link>
    <a :href="to" class="nav-link" target="_blank" v-else>
      <i :class="icon" data-popup="tooltip" :title="text"></i>
      <span>{{ text }}</span>
    </a>
  </li>
</template>

<script>
export default {
  name: "SidenavItem",

  props: {
    icon: {
      type: String,
      default: "icon-home",
      required: true,
    },
    text: {
      type: String,
      default: "Home",
      required: true,
    },
    to: {
      type: String,
      default: "/",
      required: true,
    },
    blank: {
      type: Boolean,
      default: false,
    },
    badge: {
      type: Number,
      required: false,
    },
  },

  computed: {
    isActive() {
      return this.$route.name === this.to;
    },
  },
};
</script>
