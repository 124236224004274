<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">{{ title }}</h3>
    </div>
    <div class="card-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "PageCard",

  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
