<template>
  <Page :links="[{ to: 'CreateRedirect', text: 'New Record', iconName: 'icon-plus3' }]">
    <PageCard title="Redirects">
      <el-table :data="paginatedData" ref="table">
        <el-table-column prop="oldUrl" label="Old Url" sortable>
          <template slot-scope="scope">
            <a :href="scope.row.oldUrl" target="_blank">{{ scope.row.oldUrl }} </a>
          </template>
        </el-table-column>
        <el-table-column prop="newUrl" label="New Url" sortable>
          <template slot-scope="scope">
            <a :href="scope.row.newUrl" target="_blank">{{ scope.row.newUrl }} </a>
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="Created Date" sortable width="150px" align="center">
          <template slot-scope="scope">
            {{ $formatDate(scope.row.createdAt) }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="220px">
          <template slot="header">
            <input class="form-control" v-model="searchTerm" placeholder="Type to search" />
          </template>
          <template slot-scope="scope">
            <el-button @click.prevent="handleEdit(scope.row)" icon="icon-pencil" size="mini" class="mr-2" />
            <el-button @click.prevent="handleDelete(scope.row)" icon="icon-trash" size="mini" type="danger" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="d-flex justify-content-center mt-3"
        :current-page="currentPage"
        :page-size="pageSize"
        @current-change="(val) => (currentPage = val)"
        @size-change="(val) => (pageSize = val)"
        :page-sizes="[5, 15, 30, 50, 100]"
        background
        layout="sizes,prev,pager,next"
        :total="filteredData.length"
      />
    </PageCard>
  </Page>
</template>

<script>
import seoActions from "@/store/seo/actions.js";

let tableLoader = null;

export default {
  name: "Redirects",

  data() {
    return {
      redirects: [],
      searchTerm: "",
      currentPage: 1,
      pageSize: 15,
    };
  },

  async mounted() {
    tableLoader = this.$loading.show({ container: this.$refs.table.$el });
    this.redirects = await this.$store.dispatch(seoActions.GET_ALL_REDIRECTS);
    tableLoader.hide();
  },

  methods: {
    handleDelete(redirect) {
      this.$confirm("Are you sure to delete this record?", async () => {
        tableLoader = this.$loading.show({ container: this.$refs.table.$el });
        await this.$store.dispatch(seoActions.DELETE_REDIRECT, redirect.id);
        this.redirects.splice(this.redirects.indexOf(redirect), 1);
        tableLoader.hide();
      });
    },
  },

  computed: {
    filteredData() {
      return this.redirects.filter((x) => !this.searchTerm || x.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
    paginatedData() {
      return this.filteredData.slice(this.pageSize * this.currentPage - this.pageSize, this.pageSize * this.currentPage);
    },
  },

  metaInfo() {
    return {
      title: "Redirects",
    };
  },
};
</script>
