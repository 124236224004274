<template>
  <Page :links="[{ to: 'CreateOffice', text: 'New Record', iconName: 'icon-plus3' }]">
    <PageCard title="Offices">
      <el-table :data="paginatedData" ref="table">
        <el-table-column prop="name" label="Name" />
        <el-table-column prop="createdAt" label="Created Date" sortable width="150px" align="center">
          <template slot-scope="scope">
            {{ $formatDate(scope.row.createdAt) }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="220px">
          <template slot="header">
            <input class="form-control" v-model="searchTerm" placeholder="Type to search" />
          </template>
          <template slot-scope="scope">
            <el-button @click.prevent="handleEdit(scope.row)" icon="icon-pencil" size="mini" class="mr-2" />
            <el-button @click.prevent="handleDelete(scope.row)" icon="icon-trash" size="mini" type="danger" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="d-flex justify-content-center mt-3"
        :current-page="currentPage"
        :page-size="pageSize"
        @current-change="(val) => (currentPage = val)"
        @size-change="(val) => (pageSize = val)"
        :page-sizes="[5, 15, 30, 50, 100]"
        background
        layout="sizes,prev,pager,next"
        :total="filteredData.length"
      />
    </PageCard>
  </Page>
</template>

<script>
import officeActions from "@/store/office/actions.js";

let tableLoader = null;

export default {
  name: "Offices",

  data() {
    return {
      offices: [],
      searchTerm: "",
      currentPage: 1,
      pageSize: 15,
    };
  },

  async mounted() {
    tableLoader = this.$loading.show({ container: this.$refs.table.$el });
    this.offices = await this.$store.dispatch(officeActions.GET_ALL_OFFICES);
    tableLoader.hide();
  },

  methods: {
    handleEdit(office) {
      this.$router.push({ name: "EditOffice", params: { id: office.id } });
    },

    handleDelete(office) {
      this.$confirm("Are you sure to delete this record?", async () => {
        tableLoader = this.$loading.show({ container: this.$refs.table.$el });
        await this.$store.dispatch(officeActions.DELETE_OFFICE, office.id);
        this.offices.splice(this.offices.indexOf(office), 1);
        tableLoader.hide();
      });
    },
  },

  computed: {
    filteredData() {
      return this.offices.filter((x) => !this.searchTerm || x.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
    paginatedData() {
      return this.filteredData.slice(this.pageSize * this.currentPage - this.pageSize, this.pageSize * this.currentPage);
    },
  },

  metaInfo() {
    return {
      title: "Offices",
    };
  },
};
</script>
