<template>
  <Page :links="[{ to: 'BlogPostCategories', text: 'Back to List', iconName: 'icon-arrow-right6' }]">
    <PageCard title="Add & Update Blog Post Category">
      <form method="post" @submit.prevent="handleSubmit" ref="form">
        <div class="row">
          <div class="form-group col-12">
            <label>Name</label>
            <input
              v-model.trim="blogPostCategory.name"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.blogPostCategory.name.$error }"
            />
            <div v-if="submitted && $v.blogPostCategory.name.$error" class="invalid-feedback">
              <span v-if="!$v.blogPostCategory.name.required">Name is required</span>
              <span v-if="!$v.blogPostCategory.name.maxLength">Name must be less than 255 characters</span>
            </div>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button v-if="isEdit" @click.prevent="handleDelete" type="button" class="btn btn-danger mr-2">Delete</button>
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </form>
    </PageCard>
  </Page>
</template>

<script>
import blogActions from "@/store/blog/actions.js";
import { required, maxLength } from "vuelidate/lib/validators";

let formLoader = null;

export default {
  name: "AddOrUpdateBlogPostCategory",

  data() {
    return {
      blogPostCategory: {
        name: "",
      },

      submitted: false,
    };
  },

  async mounted() {
    if (this.isEdit) {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.blogPostCategory = await this.$store.dispatch(blogActions.GET_BLOG_POST_CATEGORY_BY_ID, this.$route.params.id);
      formLoader.hide();
    }
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        formLoader.hide();
        return;
      }

      await this.$store.dispatch(blogActions.ADD_OR_UPDATE_BLOG_POST_CATEGORY, this.blogPostCategory);
      formLoader.hide();
    },

    handleDelete() {
      this.$confirm("Are you sure to delete this record?", async () => {
        formLoader = this.$loading.show({ container: this.$refs.form });
        await this.$store.dispatch(blogActions.DELETE_BLOG_POST_CATEGORY, this.blogPostCategory.id);
        formLoader.hide();
        this.$router.push({ name: "BlogPostCategories" });
      });
    },
  },

  computed: {
    isEdit() {
      return this.$router.currentRoute.name === "EditBlogPostCategory";
    },
  },

  validations() {
    return {
      blogPostCategory: {
        name: { required, maxLength: maxLength(255) },
      },
    };
  },

  metaInfo() {
    return {
      title: "Add & Update Blog Post Category",
    };
  },
};
</script>
