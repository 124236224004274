import actionTypes from "./actions.js";
import mutationTypes from "./mutations.js";
import httpClient from "@/libs/axios.js";

const state = {
  unreadContactCount: null,
};

const mutations = {
  [mutationTypes.SET_UNREAD_CONTACT_COUNT](state, count) {
    state.unreadContactCount = count;
  },
};

const actions = {
  async [actionTypes.GET_ALL_CONTACTS]() {
    const { data: result } = await httpClient.get("/contact");
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.GET_CONTACT_BY_ID]({ commit }, id) {
    const { data: result } = await httpClient.get(`/contact/${id}`);
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.DELETE_CONTACT]({ dispatch }, id) {
    const { data: result } = await httpClient.delete(`/contact/${id}`);
    if (result.success) {
      await dispatch(actionTypes.GET_UNREAD_CONTACT_COUNT);
      return result.data;
    }
  },
  async [actionTypes.UPDATE_READ_STATUS]({ dispatch }, { id, isRead }) {
    const { data: result } = await httpClient.patch(`/contact/${id}/read-status`, { isRead });
    if (result.success) {
      await dispatch(actionTypes.GET_UNREAD_CONTACT_COUNT);
    }

    return result.success;
  },
  async [actionTypes.GET_UNREAD_CONTACT_COUNT]({ commit }) {
    const { data: result } = await httpClient.get("/contact/unread-count");
    if (result.success) {
      commit(mutationTypes.SET_UNREAD_CONTACT_COUNT, result.data);
      return result.data;
    }
  },
};

const getters = {
  unreadContactCount: (state) => state.unreadContactCount,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
