<template>
  <Page :links="[{ to: 'CreateBlogPostWriter', text: 'New Record', iconName: 'icon-plus3' }]">
    <PageCard title="Blog Post Writers">
      <el-table :data="paginatedData" ref="table">
        <el-table-column prop="firstNmame" label="Name" sortable>
          <template slot-scope="scope">
            <span>{{ scope.row.firstName }} {{ scope.row.lastName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="Created Date" sortable width="150px" align="center">
          <template slot-scope="scope">
            {{ $formatDate(scope.row.createdAt) }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="220px">
          <template slot="header">
            <input class="form-control" v-model="searchTerm" placeholder="Type to search" />
          </template>
          <template slot-scope="scope">
            <el-button @click.prevent="handleEdit(scope.row)" icon="icon-pencil" size="mini" class="mr-2" />
            <el-button @click.prevent="handleDelete(scope.row)" icon="icon-trash" size="mini" type="danger" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="d-flex justify-content-center mt-3"
        :current-page="currentPage"
        :page-size="pageSize"
        @current-change="(val) => (currentPage = val)"
        @size-change="(val) => (pageSize = val)"
        :page-sizes="[5, 15, 30, 50, 100]"
        background
        layout="sizes,prev,pager,next"
        :total="filteredData.length"
      />
    </PageCard>
  </Page>
</template>

<script>
import blogActions from "@/store/blog/actions.js";

let tableLoader = null;

export default {
  name: "BlogPostWriters",

  data() {
    return {
      blogPostWriters: [],
      searchTerm: "",
      currentPage: 1,
      pageSize: 15,
    };
  },

  async mounted() {
    tableLoader = this.$loading.show({ container: this.$refs.table.$el });
    this.blogPostWriters = await this.$store.dispatch(blogActions.GET_ALL_BLOG_POST_WRITERS);
    tableLoader.hide();
  },

  methods: {
    handleEdit(writer) {
      this.$router.push({ name: "EditBlogPostWriter", params: { id: writer.id } });
    },

    handleDelete(writer) {
      this.$confirm("Are you sure to delete this record?", async () => {
        tableLoader = this.$loading.show({ container: this.$refs.table.$el });
        await this.$store.dispatch(blogActions.DELETE_BLOG_POST_WRITER, writer.id);
        this.blogPostWriters.splice(this.blogPostWriters.indexOf(writer), 1);
        tableLoader.hide();
      });
    },
  },

  computed: {
    filteredData() {
      return this.blogPostWriters.filter(
        (x) =>
          !this.searchTerm ||
          x.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          x.lastName.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    paginatedData() {
      return this.filteredData.slice(this.pageSize * this.currentPage - this.pageSize, this.pageSize * this.currentPage);
    },
  },

  metaInfo() {
    return {
      title: "Blog Post Writers",
    };
  },
};
</script>
