<template>
  <Page>
    <form @submit.prevent="handleSubmit" ref="form">
      <PageCard title="About Company Content">
        <div class="row">
          <div class="form-group col-12">
            <label>Footer Content</label>
            <textarea
              class="form-control"
              v-model="content.footerContent"
              :class="{ 'is-invalid': submitted && $v.content.footerContent.$error }"
            />
            <div v-if="submitted && $v.content.footerContent.$error" class="invalid-feedback"></div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12">
            <label>Address</label>
            <textarea
              v-model="content.address"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.content.address.$error }"
            ></textarea>
            <div v-if="submitted && $v.content.address.$error" class="invalid-feedback"></div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-6">
            <label>Email</label>
            <input v-model="content.email" class="form-control" :class="{ 'is-invalid': submitted && $v.content.email.$error }" />
            <div v-if="submitted && $v.content.email.$error" class="invalid-feedback"></div>
          </div>
          <div class="form-group col-6">
            <label>Phone</label>
            <input v-model="content.phone" class="form-control" :class="{ 'is-invalid': submitted && $v.content.phone.$error }" />
            <div v-if="submitted && $v.content.phone.$error" class="invalid-feedback"></div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label>Facebook</label>
            <input v-model="content.facebook" class="form-control" :class="{ 'is-invalid': submitted && $v.content.facebook.$error }" />
            <div v-if="submitted && $v.content.facebook.$error" class="invalid-feedback"></div>
          </div>
          <div class="form-group col-md-6">
            <label>LinkedIn</label>
            <input v-model="content.linkedin" class="form-control" :class="{ 'is-invalid': submitted && $v.content.linkedin.$error }" />
            <div v-if="submitted && $v.content.linkedin.$error" class="invalid-feedback"></div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label>Twitter</label>
            <input v-model="content.twitter" class="form-control" :class="{ 'is-invalid': submitted && $v.content.twitter.$error }" />
            <div v-if="submitted && $v.content.twitter.$error" class="invalid-feedback"></div>
          </div>
          <div class="form-group col-md-6">
            <label>Instagram</label>
            <input v-model="content.instagram" class="form-control" :class="{ 'is-invalid': submitted && $v.content.instagram.$error }" />
            <div v-if="submitted && $v.content.instagram.$error" class="invalid-feedback"></div>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </PageCard>
    </form>
  </Page>
</template>

<script>
import companyActions from "@/store/company/actions.js";

let formLoader = null;

export default {
  name: "EditAboutCompanyContent",

  data() {
    return {
      content: {
        footerContent: "",
        facebook: "",
        linkedin: "",
        twitter: "",
        instagram: "",
        address: "",
        email: "",
        phone: "",
      },

      submitted: false,
    };
  },

  async mounted() {
    formLoader = this.$loading.show({ container: this.$refs.form });
    this.content = await this.$store.dispatch(companyActions.GET_ABOUT_COMPANY_CONTENT);
    formLoader.hide();
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        formLoader.hide();
        return;
      }

      await this.$store.dispatch(companyActions.UPDATE_ABOUT_COMPANY_CONTENT, this.content);
      formLoader.hide();
    },
  },

  validations() {
    return {
      content: {
        footerContent: {},
        facebook: {},
        linkedin: {},
        twitter: {},
        instagram: {},
        address: {},
        email: {},
        phone: {},
      },
    };
  },
};
</script>
