<template>
  <Page :links="[{ to: 'Users', text: 'New Record', iconName: 'icon-plus3' }]">
    <form @submit.prevent="handleSubmit" ref="form">
      <PageCard title="Add & Update User">
        <div class="row">
          <div class="form-group col-md-6">
            <label>First Name</label>
            <input v-model="user.firstName" class="form-control" :class="{ 'is-invalid': submitted && $v.user.firstName.$error }" />
            <div v-if="submitted && $v.user.firstName.$error" class="invalid-feedback">
              <span v-if="!$v.user.firstName.required">This field is required</span>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Last Name</label>
            <input v-model="user.lastName" class="form-control" :class="{ 'is-invalid': submitted && $v.user.lastName.$error }" />
            <div v-if="submitted && $v.user.lastName.$error" class="invalid-feedback">
              <span v-if="!$v.user.lastName.required">This field is required</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label>Email</label>
            <input v-model="user.email" class="form-control" :class="{ 'is-invalid': submitted && $v.user.email.$error }" />
            <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
              <span v-if="!$v.user.email.required">This field is required</span>
            </div>
          </div>
          <div class="form-group col-md-6" v-if="!isEdit">
            <label>Password</label>
            <input
              v-model="user.password"
              type="password"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.user.password.$error }"
            />
            <div v-if="submitted && $v.user.password.$error" class="invalid-feedback">
              <span v-if="!$v.user.password.required">This field is required</span>
            </div>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button v-if="isEdit" @click.prevent="handleDelete" type="button" class="btn btn-danger mr-2">Delete</button>
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </PageCard>
    </form>
  </Page>
</template>

<script>
import userActions from "@/store/user/actions.js";
import { required } from "vuelidate/lib/validators";

let formLoader = null;

export default {
  name: "AddOrUpdateUser",

  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      },

      submitted: false,
    };
  },

  async mounted() {
    if (this.isEdit) {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.user = await this.$store.dispatch(userActions.GET_USER_BY_ID, this.$route.params.id);
      formLoader.hide();
    }
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        formLoader.hide();
        return;
      }

      await this.$store.dispatch(userActions.ADD_OR_UPDATE_USER, this.user);
      formLoader.hide();
    },

    handleDelete() {
      this.$confirm("Are you sure to delete this record?", async () => {
        formLoader = this.$loading.show({ container: this.$refs.form });
        await this.$store.dispatch(userActions.DELETE_USER, this.user.id);
        formLoader.hide();
        this.$router.push({ name: "Users" });
      });
    },
  },

  computed: {
    isEdit() {
      return this.$router.currentRoute.name === "EditUser";
    },
  },

  validations() {
    return {
      user: {
        firstName: { required },
        lastName: { required },
        email: { required },
        password: { required: !this.isEdit },
      },
    };
  },

  metaInfo() {
    return {
      title: "Add & Update User",
    };
  },
};
</script>
