<template>
  <div id="app">
    <notifications group="top-right" position="top right" />
    <notifications group="top-left" position="top left" />
    <notifications group="bottom-left" position="bottom left" />
    <notifications group="bottom-right" position="bottom right" />
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
import "@/assets/css/bootstrap_limitless.min.css";
import "@/assets/css/bootstrap.min.css";
import "@/assets/css/colors.min.css";
import "@/assets/css/layout.min.css";
import "@/assets/css/components.min.css";
import "@/assets/icons/icomoon/styles.min.css";
import "@/assets/css/admin.css";
import accountActions from "@/store/account/actions.js";

export default {
  name: "App",

  beforeMount() {
    const accessToken = localStorage.getItem("access_token");
    this.$store.dispatch(accountActions.IS_AUTHENTICATED, accessToken != null && accessToken != undefined);
  },

  metaInfo() {
    return {
      titleTemplate: "%s - Eurokick Admin",
    };
  },
};
</script>
