<template>
  <Page :links="[{ to: 'Redirects', text: 'Back to List', iconName: 'icon-arrow-right6' }]">
    <PageCard title="Add & Update Redirect">
      <form method="post" @submit.prevent="handleSubmit" ref="form">
        <div class="row">
          <div class="form-group col-12">
            <label>Old url</label>
            <input v-model.trim="redirect.oldUrl" class="form-control" :class="{ 'is-invalid': submitted && $v.redirect.oldUrl.$error }" />
            <div v-if="submitted && $v.redirect.oldUrl.$error" class="invalid-feedback">
              <span v-if="!$v.redirect.oldUrl.required">Old url is required</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12">
            <label>New Url</label>
            <input v-model.trim="redirect.newUrl" class="form-control" :class="{ 'is-invalid': submitted && $v.redirect.newUrl.$error }" />
            <div v-if="submitted && $v.redirect.newUrl.$error" class="invalid-feedback">
              <span v-if="!$v.redirect.newUrl.required">New Url is required</span>
            </div>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button v-if="isEdit" @click.prevent="handleDelete" type="button" class="btn btn-danger mr-2">Delete</button>
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </form>
    </PageCard>
  </Page>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import seoActions from "@/store/seo/actions.js";

let formLoader = null;

export default {
  name: "AddOrUpdateRedirect",

  data() {
    return {
      redirect: {
        oldUrl: "",
        newUrl: "",
      },

      submitted: false,
    };
  },

  async mounted() {
    if (this.isEdit) {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.redirect = await this.$store.dispatch(seoActions.GET_REDIRECT_BY_ID, this.$route.params.id);
      formLoader.hide();
    }
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        formLoader.hide();
        return;
      }

      await this.$store.dispatch(seoActions.ADD_OR_UPDATE_REDIRECT, this.redirect);
      formLoader.hide();
    },

    handleDelete() {
      this.$confirm("Are you sure to delete this record?", async () => {
        formLoader = this.$loading.show({ container: this.$refs.form });
        await this.$store.dispatch(seoActions.DELETE_REDIRECT, this.redirect.id);
        formLoader.hide();
        this.$router.push({ name: "Redirects" });
      });
    },
  },

  computed: {
    isEdit() {
      return this.$router.currentRoute.name === "EditRedirect";
    },
  },

  validations() {
    return {
      redirect: {
        oldUrl: { required },
        newUrl: { required },
      },
    };
  },
};
</script>
