<template>
  <Page :links="[{ to: 'Contacts', text: 'Back to List', iconName: 'icon-arrow-right6' }]">
    <PageCard title="Contact Details" v-if="contact" ref="card">
      <div class="row">
        <div class="contact-field col-md-6">
          <label>First Name</label>
          <p>{{ contact.firstName }}</p>
        </div>
        <div class="contact-field col-md-6">
          <label>Last Name</label>
          <p>{{ contact.lastName }}</p>
        </div>
      </div>
      <div class="row">
        <div class="contact-field col-md-6">
          <label>Email</label>
          <p>{{ contact.email }}</p>
        </div>
        <div class="contact-field col-md-6">
          <label>Phone</label>
          <p>{{ contact.phone }}</p>
        </div>
      </div>
      <div class="row">
        <div class="contact-field col-md-6">
          <label>Contact Type</label>
          <p>{{ contactTypes.find((x) => x.value === contact.contactType).displayName }}</p>
        </div>
      </div>
      <div class="row">
        <div class="contact-field col-12">
          <label>Message</label>
          <p v-html="contact.message"></p>
        </div>
      </div>
      <div class="row text-right">
        <div class="contact-field col-md-12">
          <button @click.prevent="handleDelete" type="button" class="btn btn-danger mr-2">Delete</button>
          <button v-if="contact.isRead" class="btn btn-warning" @click.prevent="markContact(false)" ref="statusBtn">
            <i class="icon-cross"></i>
            <span>Mark as Unread</span>
          </button>
          <button v-else class="btn btn-primary" @click.prevent="markContact(true)" ref="statusBtn">
            <i class="icon-check"></i>
            <span>Mark as Read</span>
          </button>
        </div>
      </div>
    </PageCard>
  </Page>
</template>

<script>
import contactActions from "@/store/contact/actions.js";
import { mapGetters } from "vuex";

let cardLoader = null;

export default {
  name: "ContactDetails",

  data() {
    return {
      contact: null,
    };
  },

  async mounted() {
    cardLoader = this.$loading.show({ container: this.$refs.card });
    this.contact = await this.$store.dispatch(contactActions.GET_CONTACT_BY_ID, this.$route.params.id);
    cardLoader.hide();
  },

  methods: {
    handleDelete() {
      this.$confirm("Are you sure to delete this record?", async () => {
        cardLoader = this.$loading.show({ container: this.$refs.card.$el });
        await this.$store.dispatch(contactActions.DELETE_CONTACT, this.contact.id);
        cardLoader.hide();
        this.$router.push({ name: "Contacts" });
      });
    },

    async markContact(isRead) {
      this.$refs.statusBtn.disabled = true;
      var isSuccess = await this.$store.dispatch(contactActions.UPDATE_READ_STATUS, { id: this.contact.id, isRead });
      if (isSuccess) {
        this.contact.isRead = isRead;
      }

      this.$refs.statusBtn.disabled = false;
    },
  },

  computed: {
    ...mapGetters(["contactTypes"]),
  },

  metaInfo() {
    return {
      title: "Contact Details",
    };
  },
};
</script>

<style scoped>
.contact-field p {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}
</style>
