<template>
  <div class="bg-slate-800 login-page" :style="{ backgroundImage: 'url(' + require('@/assets/img/login-background.jpg') + ')' }">
    <div class="page-content">
      <div class="content-wrapper">
        <div class="content d-flex justify-content-center align-items-center">
          <form @submit.prevent="handleSubmit" class="login-form" ref="form">
            <div class="card mb-0">
              <div class="card-body">
                <div class="text-center mb-3">
                  <h5 class="mb-0">Login</h5>
                  <span class="d-block text-muted">Login with your form.</span>
                </div>
                <div class="form-group form-group-feedback form-group-feedback-left">
                  <input
                    class="form-control"
                    placeholder="E-mail"
                    v-model.trim="form.email"
                    :class="{ 'is-invalid': submitted && $v.form.email.$error }"
                  />
                  <div class="form-control-feedback">
                    <i class="icon-lock2 text-muted"></i>
                  </div>
                  <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                    <span v-if="!$v.form.email.required">Email is required</span>
                    <span v-if="!$v.form.email.email">Email is invalid</span>
                  </div>
                </div>
                <div class="form-group form-group-feedback form-group-feedback-left">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    v-model="form.password"
                    :class="{ 'is-invalid': submitted && $v.form.password.$error }"
                  />
                  <div class="form-control-feedback">
                    <i class="icon-lock2 text-muted"></i>
                  </div>
                  <div v-if="submitted && $v.form.password.$error" class="invalid-feedback">
                    <span v-if="!$v.form.password.required">Password is required</span>
                  </div>
                </div>
                <div class="form-group d-flex align-items-center">
                  <el-checkbox v-model="form.isPersistent" class="text-muted checkbox-label">Remember Me</el-checkbox>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-backoffice-primary btn-block">Login</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import accountActions from "@/store/account/actions.js";
import { email, required } from "vuelidate/lib/validators";

let formLoader = null;

export default {
  name: "Login",

  data() {
    return {
      form: {
        email: "",
        password: "",
        isPersistent: false,
      },
      submitted: false,
    };
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        formLoader.hide();
        return;
      }

      await this.$store.dispatch(accountActions.LOGIN, { form: this.form, loader: formLoader });
    },
  },

  validations() {
    return {
      form: {
        email: { required, email },
        password: { required },
      },
    };
  },

  metaInfo() {
    return {
      title: "Login",
    };
  },
};
</script>

<style>
.login-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}
.login-page .card {
  border-radius: 10px;
}
.login-page form input,
button[type="submit"] {
  border-radius: 10px;
}
.login-page form .checkbox-label .el-checkbox__label {
  font-size: 13px;
}
</style>
