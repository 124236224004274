<template>
  <Page :links="[{ to: 'BlogPostWriters', text: 'Back to List', iconName: 'icon-arrow-right6' }]">
    <PageCard title="Add & Update Blog Post Writer">
      <form method="post" @submit.prevent="handleSubmit" ref="form">
        <div class="row">
          <div class="col-md-3">
            <div class="row">
              <div class="form-group col-12">
                <label>Image</label>
                <div class="dropzone" id="imageDropzone" ref="imageDropzone"></div>
                <div v-if="submitted && $v.blogPostWriter.image.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPostWriter.image.required">Image is required</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="form-group col-md-6">
                <label>First Name</label>
                <input
                  v-model.trim="blogPostWriter.firstName"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.blogPostWriter.firstName.$error }"
                />
                <div v-if="submitted && $v.blogPostWriter.firstName.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPostWriter.firstName.required">First name is required</span>
                  <span v-if="!$v.blogPostWriter.firstName.maxLength">First name must be less than 255 characters</span>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Last Name</label>
                <input
                  v-model.trim="blogPostWriter.lastName"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.blogPostWriter.lastName.$error }"
                />
                <div v-if="submitted && $v.blogPostWriter.lastName.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPostWriter.lastName.required">Last name is required</span>
                  <span v-if="!$v.blogPostWriter.lastName.maxLength">Last name must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>External Link</label>
                <input
                  v-model.trim="blogPostWriter.externalLink"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.blogPostWriter.externalLink.$error }"
                />
                <div v-if="submitted && $v.blogPostWriter.externalLink.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPostWriter.externalLink.maxLength">External link must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Blog Posts</label>
                <el-select v-model="blogPostWriter.blogPosts" multiple class="w-100">
                  <el-option
                    v-for="blogPost in blogPosts"
                    :key="blogPost.id"
                    :label="blogPost.title"
                    :value="blogPost.id"
                    :class="{ 'is-invalid': submitted && $v.blogPostWriter.blogPosts.$error }"
                  />
                </el-select>
                <div v-if="submitted && $v.blogPostWriter.blogPosts.$error" class="invalid-feedback"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button v-if="isEdit" @click.prevent="handleDelete" type="button" class="btn btn-danger mr-2">Delete</button>
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </form>
    </PageCard>
  </Page>
</template>

<script>
import blogActions from "@/store/blog/actions.js";
import { required, maxLength } from "vuelidate/lib/validators";
import Dropzone from "dropzone";

let formLoader = null;
let imageDropzoneLoader = null;

export default {
  name: "AddOrUpdateBlogPostWriter",

  data() {
    return {
      blogPostWriter: {
        firstName: "",
        lastName: "",
        image: "",
        externalLink: "",
        blogPosts: [],
      },

      blogPosts: [],
      submitted: false,
    };
  },

  async mounted() {
    formLoader = this.$loading.show({ container: this.$refs.form });
    this.blogPosts = await this.$store.dispatch(blogActions.GET_ALL_BLOG_POSTS);

    if (this.isEdit) {
      var writer = await this.$store.dispatch(blogActions.GET_BLOG_POST_WRITER_BY_ID, this.$route.params.id);
      writer.blogPosts = writer.blogPosts.map((blogPost) => blogPost.id);
      this.blogPostWriter = writer;
    }

    this.$nextTick(() => {
      this.initDropzone();
      formLoader.hide();
    });
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        formLoader.hide();
        return;
      }

      await this.$store.dispatch(blogActions.ADD_OR_UPDATE_BLOG_POST_WRITER, this.blogPostWriter);
      formLoader.hide();
    },

    handleDelete() {
      this.$confirm("Are you sure to delete this record?", async () => {
        formLoader = this.$loading.show({ container: this.$refs.form });
        await this.$store.dispatch(blogActions.DELETE_BLOG_POST_WRITER, this.blogPostWriter.id);
        formLoader.hide();
        this.$router.push({ name: "BlogPostWriters" });
      });
    },

    initDropzone() {
      let imageDropzone = new Dropzone("#imageDropzone", {
        url: `${process.env.VUE_APP_BASE_API_URL}/general/upload-file?uploadType=blog`,
        maxFiles: 1,
        acceptedFiles: "image/*",
        dictDefaultMessage: "",
        addRemoveLinks: true,
        headers: {
          authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        withCredentials: true,
      });

      imageDropzone.on("addedfile", () => {
        imageDropzoneLoader = this.$loading.show({ container: this.$refs.imageDropzone });
      });

      imageDropzone.on("success", (file, response) => {
        this.blogPostWriter.image = response.data;
      });

      imageDropzone.on("removedfile", () => {
        this.blogPostWriter.image = "";
      });

      imageDropzone.on("complete", () => {
        imageDropzoneLoader.hide();
      });

      if (this.isEdit) {
        const imageMockFile = { name: "", dataURL: this.blogPostWriter.image, size: 12345, type: "image/png" };

        imageDropzone.emit("addedfile", imageMockFile);
        imageDropzone.emit("success", imageMockFile, { data: this.blogPostWriter.image });
        imageDropzone.emit("thumbnail", imageMockFile, this.blogPostWriter.image);
        imageDropzone.emit("complete", imageMockFile);
      }
    },
  },

  computed: {
    isEdit() {
      return this.$router.currentRoute.name === "EditBlogPostWriter";
    },
  },

  validations() {
    return {
      blogPostWriter: {
        firstName: { required, maxLength: maxLength(255) },
        lastName: { required, maxLength: maxLength(255) },
        image: { required },
        externalLink: { maxLength: maxLength(255) },
        blogPosts: {},
      },
    };
  },

  metaInfo() {
    return {
      title: "Add & Update Blog Post Writer",
    };
  },
};
</script>
