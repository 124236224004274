<template>
  <Page :links="[{ to: 'CreateTeamMember', text: 'New Record', iconName: 'icon-plus3' }]">
    <PageCard title="Team Members">
      <el-table :data="paginatedData" ref="table">
        <el-table-column prop="firstName" label="Full Name" sortable>
          <template slot-scope="scope">
            <span>{{ scope.row.firstName }} {{ scope.row.lastName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="Created Date" sortable width="150px" align="center">
          <template slot-scope="scope">
            {{ $formatDate(scope.row.createdAt) }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="220px">
          <template slot="header">
            <input class="form-control" v-model="searchTerm" placeholder="Type to search" />
          </template>
          <template slot-scope="scope">
            <el-button @click.prevent="handleEdit(scope.row)" icon="icon-pencil" size="mini" class="mr-2" />
            <el-button @click.prevent="handleDelete(scope.row)" icon="icon-trash" size="mini" type="danger" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="d-flex justify-content-center mt-3"
        :current-page="currentPage"
        :page-size="pageSize"
        @current-change="(val) => (currentPage = val)"
        @size-change="(val) => (pageSize = val)"
        :page-sizes="[5, 15, 30, 50, 100]"
        background
        layout="sizes,prev,pager,next"
        :total="filteredData.length"
      />
    </PageCard>
    <el-dialog title="Redirect" :visible.sync="showRedirectDialog" width="50%">
      <div class="row">
        <div class="form-group col-12">
          <label>Redirect To</label>
          <input v-model="redirectTo" class="form-control" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button type="button" class="btn btn-warning mr-2" @click="handleRedirect(false)">Delete without Redirect</button>
        <button type="button" class="btn btn-backoffice-primary" @click="handleRedirect(true)" v-if="redirectTo">
          Redirect and Delete
        </button>
      </span>
    </el-dialog>
  </Page>
</template>

<script>
import teamMemberActions from "@/store/teamMember/actions.js";

let tableLoader = null;

export default {
  name: "TeamMembers",

  data() {
    return {
      teamMembers: [],
      searchTerm: "",
      currentPage: 1,
      pageSize: 15,
      showRedirectDialog: false,
      redirectTo: "",
      selectedTeamMember: null,
    };
  },

  async mounted() {
    tableLoader = this.$loading.show({ container: this.$refs.table.$el });
    this.teamMembers = await this.$store.dispatch(teamMemberActions.GET_ALL_TEAM_MEMBERS);
    tableLoader.hide();
  },

  methods: {
    handleEdit(teamMember) {
      this.$router.push({ name: "EditTeamMember", params: { id: teamMember.id } });
    },

    handleDelete(teamMember) {
      this.$confirm("Are you sure to delete this record?", () => {
        this.selectedTeamMember = teamMember;
        this.showRedirectDialog = true;
      });
    },

    async handleRedirect(redirect) {
      tableLoader = this.$loading.show({ container: this.$refs.table.$el });
      if (redirect) {
        await this.$store.dispatch(teamMemberActions.DELETE_TEAM_MEMBER, {
          id: this.selectedTeamMember.id,
          redirectTo: this.redirectTo,
        });
      } else {
        await this.$store.dispatch(teamMemberActions.DELETE_TEAM_MEMBER, {
          id: this.selectedTeamMember.id,
        });
      }

      this.teamMembers.splice(this.teamMembers.indexOf(this.selectedTeamMember), 1);
      tableLoader.hide();
      this.showRedirectDialog = false;
      this.redirectTo = "";
    },
  },

  computed: {
    filteredData() {
      return this.teamMembers.filter(
        (x) =>
          !this.searchTerm ||
          x.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          x.lastName.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    paginatedData() {
      return this.filteredData.slice(this.pageSize * this.currentPage - this.pageSize, this.pageSize * this.currentPage);
    },
    teamMembersSelectList() {
      if (this.selectedTeamMember) {
        return this.teamMembers.filter((x) => x.id !== this.selectedTeamMember.id);
      }

      return this.teamMembers;
    },
  },

  metaInfo() {
    return {
      title: "Team Members",
    };
  },
};
</script>
