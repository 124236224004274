import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueNotification from "vue-notification";
import VueMeta from "vue-meta";
import Vuelidate from "vuelidate";
import VueMoment from "vue-moment";
import VueLoadingOverlay from "vue-loading-overlay";
import ElementUI, { MessageBox } from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import "element-ui/lib/theme-chalk/index.css";
import "bootstrap/dist/js/bootstrap.js";
import HeaderMenu from "./components/common/HeaderMenu.vue";
import Page from "./components/common/Page.vue";
import PageCard from "./components/common/PageCard.vue";
import Dropzone from "dropzone";
import "vue-loading-overlay/dist/vue-loading.css";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import slugify from "slugify";

Vue.config.productionTip = false;

Vue.use(VueNotification);
Vue.use(VueMeta);
Vue.use(Vuelidate);
Vue.use(VueMoment);
Vue.use(ElementUI, { locale });
Vue.use(VueLoadingOverlay, { color: "#ddd" });
Vue.use(CKEditor);

Vue.component("HeaderMenu", HeaderMenu);
Vue.component("Page", Page);
Vue.component("PageCard", PageCard);

Dropzone.autoDiscover = false;

Vue.prototype.$message = {
  success: (message, group = "top-right", duration = 5000) => {
    Vue.notify({
      group: group,
      text: message,
      duration: duration,
      type: "success",
    });
  },

  error: (message, group = "top-right", duration = 5000) => {
    Vue.notify({
      group: group,
      text: message,
      duration: duration,
      type: "error",
    });
  },

  warning: (message, group = "top-right", duration = 5000) => {
    Vue.notify({
      group: group,
      text: message,
      duration: duration,
      type: "warn",
    });
  },

  info: (message, group = "top-right", duration = 5000) => {
    Vue.notify({
      group: group,
      text: message,
      duration: duration,
      type: "info",
    });
  },
};

Vue.prototype.$confirm = (message, callback) => {
  const params = {
    type: "warning",
    confirmButtonText: "Yes",
    cancelButtonText: "No",
    center: true,
  };

  MessageBox.confirm(message, "Confirmation", params)
    .then(() => {
      callback();
    })
    .catch(() => {});
};

Vue.prototype.$formatDate = (date) => {
  return Vue.moment(date).format("DD.MM.YYYY");
};

Vue.prototype.$getHour = (date) => {
  return Vue.moment(date).format("HH:mm");
};

Vue.prototype.$slugify = (value) => {
  return slugify(value, { lower: true });
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
