<template>
  <Page>
    <PageCard title="Update Profile">
      <form @submit.prevent="handleSubmit" v-if="user" ref="form">
        <div class="row">
          <div class="form-group col-md-6">
            <label>First Name</label>
            <input v-model.trim="user.firstName" class="form-control" :class="{ 'is-invalid': submitted && $v.user.firstName.$error }" />
            <div v-if="submitted && $v.user.firstName.$error" class="invalid-feedback">
              <span v-if="!$v.user.firstName.required">First name is required</span>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Last Name</label>
            <input v-model.trim="user.lastName" class="form-control" :class="{ 'is-invalid': submitted && $v.user.lastName.$error }" />
            <div v-if="submitted && $v.user.lastName.$error" class="invalid-feedback">
              <span v-if="!$v.user.lastName.required">First name is required</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label>Email</label>
            <input v-model.trim="user.email" class="form-control" :class="{ 'is-invalid': submitted && $v.user.email.$error }" />
            <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
              <span v-if="!$v.user.email.required">Email is required</span>
              <span v-if="!$v.user.email.email">Email is invalid</span>
            </div>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </form>
    </PageCard>
  </Page>
</template>

<script>
import { mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import accountActions from "@/store/account/actions.js";

let formLoader = null;

export default {
  name: "UpdateProfile",

  data() {
    return {
      submitted: false,
    };
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({
        container: this.$refs.form,
      });
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.$store.dispatch(accountActions.UPDATE_PROFILE, {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
        });
      }

      formLoader.hide();
    },
  },

  computed: {
    ...mapGetters(["user"]),
  },

  validations() {
    return {
      user: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
      },
    };
  },

  metaInfo() {
    return {
      title: "Update Profile",
    };
  },
};
</script>
