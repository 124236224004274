<template>
  <div>
    <Header />
    <transition name="fade">
      <router-view />
    </transition>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import accountActions from "@/store/account/actions.js";
import { mapGetters } from "vuex";

export default {
  name: "AuthorizedLayout",

  components: {
    Header,
    Footer,
  },

  async beforeMount() {
    if (!this.isAuthenticated) {
      this.$store.dispatch(accountActions.LOGOUT);
      return;
    }

    await this.$store.dispatch(accountActions.GET_ACCOUNT);
  },

  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
};
</script>
