export default {
  GET_HOME_PAGE_CONTENT: "GET_HOME_PAGE_CONTENT",
  GET_FOUNDERS_PAGE_CONTENT: "GET_FOUNDERS_PAGE_CONTENT",
  GET_INSIGHTS_PAGE_CONTENT: "GET_INSIGHTS_PAGE_CONTENT",
  GET_CONTACT_PAGE_CONTENT: "GET_CONTACT_PAGE_CONTENT",
  GET_PARTNERS_PAGE_CONTENT: "GET_PARTNERS_PAGE_CONTENT",
  UPDATE_HOME_PAGE_CONTENT: "UPDATE_HOME_PAGE_CONTENT",
  UPDATE_FOUNDERS_PAGE_CONTENT: "UPDATE_FOUNDERS_PAGE_CONTENT",
  UPDATE_INSIGHTS_PAGE_CONTENT: "UPDATE_INSIGHTS_PAGE_CONTENT",
  UPDATE_CONTACT_PAGE_CONTENT: "UPDATE_CONTACT_PAGE_CONTENT",
  UPDATE_PARTNERS_PAGE_CONTENT: "UPDATE_PARTNERS_PAGE_CONTENT",
  GET_GLOBAL_META_TAGS: "GET_GLOBAL_META_TAGS",
  GET_GLOBAL_SCRIPTS: "GET_GLOBAL_SCRIPTS",
  UPDATE_GLOBAL_META_TAGS: "UPDATE_GLOBAL_META_TAGS",
  UPDATE_GLOBAL_SCRIPTS: "UPDATE_GLOBAL_SCRIPTS",
};
