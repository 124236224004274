import actionTypes from "./actions.js";
import httpClient from "@/libs/axios.js";
import router from "@/router/index.js";

const state = {};

const mutations = {};

const actions = {
  async [actionTypes.GET_ALL_INVESTMENT_CATEGORIES]() {
    const { data: result } = await httpClient.get("/investment/category");
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.GET_INVESTMENT_CATEGORY_BY_ID]({ commit }, id) {
    const { data: result } = await httpClient.get(`/investment/category/${id}`);
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.ADD_OR_UPDATE_INVESTMENT_CATEGORY]({ commit }, data) {
    const { data: result } = await httpClient.post("/investment/category", data);
    if (result.success) {
      router.push({ name: "InvestmentCategories" });
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.DELETE_INVESTMENT_CATEGORY]({ commit }, id) {
    await httpClient.delete(`/investment/category/${id}`);
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
