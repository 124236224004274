import actionTypes from "./actions.js";
import mutationTypes from "./mutations.js";

const state = {
  previewHeaderContent: {
    backgroundImage: "",
    showButton: false,
  },
};

const mutations = {
  [mutationTypes.SET_PREVIEW_HEADER_CONTENT](state, payload) {
    state.previewHeaderContent = payload;
  },
};

const actions = {
  [actionTypes.PREVIEW_HEADER_CONTENT]({ commit }, payload) {
    commit(mutationTypes.SET_PREVIEW_HEADER_CONTENT, payload);
  },
};

const getters = {
  previewHeaderContent: (state) => state.previewHeaderContent,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
