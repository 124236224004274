<template>
  <div class="sidebar sidebar-light sidebar-main sidebar-expand-md align-self-start">
    <div class="sidebar-mobile-toggler text-center">
      <a href="#" class="sidebar-mobile-main-toggle">
        <i class="icon-arrow-left8"></i>
      </a>
      <span class="font-weight-semibold">Menu</span>
      <a href="#" class="sidebar-mobile-expand">
        <i class="icon-screen-full"></i>
        <i class="icon-screen-normal"></i>
      </a>
    </div>
    <div class="sidebar-content">
      <div class="card card-sidebar-mobile">
        <div class="sidebar-user">
          <div class="card-body">
            <div class="media" v-if="user">
              <div class="mr-3">
                <img :src="user.image" width="38" height="38" class="rounded-circle" alt="" />
              </div>
              <div class="media-body">
                <div class="media-title font-weight-semibold">{{ user.firstName }} {{ user.lastName }}</div>
              </div>
              <div class="ml-3 align-self-center">
                <a href="#" class="text-white">
                  <i class="icon-cog3"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body p-0">
          <ul class="nav nav-sidebar" data-nav-type="accordion">
            <SidenavItem to="https://eurokick.com" text="Go to Website" icon="icon-new-tab" blank />
            <SidenavDivider text="General" />
            <SidenavDropdown text="Blog" icon="icon-newspaper2" :routes="blogRoutes" />
            <SidenavItem text="Offices" icon="icon-newspaper2" to="Offices" />
            <SidenavDropdown text="Partner" icon="icon-newspaper2" :routes="partnerRoutes" />
            <SidenavItem text="Team Members" icon="icon-newspaper2" to="TeamMembers" />
            <SidenavItem text="Investment Categories" icon="icon-newspaper2" to="InvestmentCategories" />
            <SidenavItem text="Contacts" icon="icon-newspaper2" to="Contacts" :badge="unreadContactCount" />
            <SidenavDropdown text="Page Contents" icon="icon-newspaper2" :routes="pageContentRoutes" />
            <SidenavDivider text="Other" />
            <SidenavItem text="About Company" icon="icon-newspaper2" to="EditAboutCompanyContent" />
            <SidenavItem text="Global Meta Tags" icon="icon-newspaper2" to="EditGlobalMetaTags" />
            <SidenavItem text="Global Scripts" icon="icon-newspaper2" to="EditGlobalScripts" />
            <SidenavItem text="Users" icon="icon-newspaper2" to="Users" />
            <SidenavItem text="Redirects" icon="icon-newspaper2" to="Redirects" />
            <SidenavDropdown text="SEO Settings" icon="icon-newspaper2" :routes="seoSettingsRoutes" />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SidenavItem from "@/components/common/sidenav/SidenavItem.vue";
import SidenavDropdown from "@/components/common/sidenav/SidenavDropdown.vue";
import SidenavDivider from "@/components/common/sidenav/SidenavDivider.vue";
import $ from "jquery";
import contactActions from "@/store/contact/actions.js";

export default {
  name: "Sidenav",

  data() {
    return {
      blogRoutes: [
        {
          text: "Blog Posts",
          name: "BlogPosts",
          icon: "icon-newspaper2",
        },
        {
          text: "Categories",
          name: "BlogPostCategories",
          icon: "icon-newspaper2",
        },
        {
          text: "Writers",
          name: "BlogPostWriters",
          icon: "icon-newspaper2",
        },
        {
          text: "Subscribers",
          name: "NewsletterSubscribers",
          icon: "icon-newspaper2",
        },
      ],
      pageContentRoutes: [
        {
          text: "Home",
          name: "EditHomePageContent",
          icon: "icon-newspaper2",
        },
        {
          text: "Founders",
          name: "EditFoundersPageContent",
          icon: "icon-newspaper2",
        },
        {
          text: "Portfolio",
          name: "EditPartnersPageContent",
          icon: "icon-newspaper2",
        },
        {
          text: "Insights",
          name: "EditInsightsPageContent",
          icon: "icon-newspaper2",
        },
        {
          text: "Contact",
          name: "EditContactPageContent",
          icon: "icon-newspaper2",
        },
      ],
      partnerRoutes: [
        {
          text: "Categories",
          name: "PartnerCategories",
          icon: "icon-newspaper2",
        },
        {
          text: "Partners",
          name: "Partners",
          icon: "icon-newspaper2",
        },
      ],
      seoSettingsRoutes: [
        {
          text: "Robots.txt",
          name: "EditRobotsTxt",
          icon: "icon-newspaper2",
        },
        {
          text: "Sitemaps",
          name: "Sitemap",
          icon: "icon-newspaper2",
        },
      ],
    };
  },

  components: {
    SidenavItem,
    SidenavDropdown,
    SidenavDivider,
  },

  async mounted() {
    var navClass = "nav-sidebar",
      navItemClass = "nav-item",
      navItemOpenClass = "nav-item-open",
      navLinkClass = "nav-link",
      navSubmenuClass = "nav-group-sub",
      navSlidingSpeed = 250;

    $("." + navClass).each(function () {
      $(this)
        .find("." + navItemClass)
        .has("." + navSubmenuClass)
        .children("." + navItemClass + " > " + "." + navLinkClass)
        .not(".disabled")
        .on("click", function (e) {
          e.preventDefault();

          var $target = $(this),
            $navSidebarMini = $(".sidebar-xs")
              .not(".sidebar-mobile-main")
              .find(".sidebar-main ." + navClass)
              .children("." + navItemClass);

          // Collapsible
          if ($target.parent("." + navItemClass).hasClass(navItemOpenClass)) {
            $target
              .parent("." + navItemClass)
              .not($navSidebarMini)
              .removeClass(navItemOpenClass)
              .children("." + navSubmenuClass)
              .slideUp(navSlidingSpeed);
          } else {
            $target
              .parent("." + navItemClass)
              .not($navSidebarMini)
              .addClass(navItemOpenClass)
              .children("." + navSubmenuClass)
              .slideDown(navSlidingSpeed);
          }

          if ($target.parents("." + navClass).data("nav-type") == "accordion") {
            $target
              .parent("." + navItemClass)
              .not($navSidebarMini)
              .siblings(":has(." + navSubmenuClass + ")")
              .removeClass(navItemOpenClass)
              .children("." + navSubmenuClass)
              .slideUp(navSlidingSpeed);
          }
        });
    });

    $(document).on("click", "." + navClass + " .disabled", function (e) {
      e.preventDefault();
    });

    $(".nav-scrollspy")
      .find("." + navItemClass)
      .has("." + navSubmenuClass)
      .children("." + navItemClass + " > " + "." + navLinkClass)
      .off("click");

    if (this.unreadContactCount === null) {
      await this.$store.dispatch(contactActions.GET_UNREAD_CONTACT_COUNT);
    }
  },

  computed: {
    ...mapGetters(["user", "navbarSize", "unreadContactCount"]),
  },
};
</script>
