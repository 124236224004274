import Vue from "vue";
import Vuex from "vuex";

import account from "./account";
import office from "./office";
import partner from "./partner";
import teamMember from "./teamMember";
import blog from "./blog";
import common from "./common";
import investment from "./investment";
import contact from "./contact";
import pageContent from "./pageContent";
import company from "./company";
import user from "./user";
import seo from "./seo";
import preview from "./preview";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    office,
    partner,
    teamMember,
    blog,
    common,
    investment,
    contact,
    pageContent,
    company,
    user,
    seo,
    preview,
  },
});
