<template>
  <Page :links="[{ to: 'Partners', text: 'Back to List', iconName: 'icon-arrow-right6' }]">
    <form method="post" @submit.prevent="handleSubmit" ref="form">
      <PageCard title="Add & Update Partner">
        <div class="row">
          <div class="col-lg-2 col-md-3">
            <div class="row">
              <div class="form-group col-12">
                <label>Image</label>
                <div class="dropzone" id="imageDropzone" ref="imageDropzone"></div>
                <div v-if="submitted && $v.partner.image.$error" class="invalid-feedback">
                  <span v-if="!$v.partner.image.required">Image is required</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">{{ partner.image }}</div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Image Alt</label>
                <input
                  v-model.trim="partner.imageAlt"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.partner.imageAlt.$error }"
                />
                <div v-if="submitted && $v.partner.imageAlt.$error" class="invalid-feedback">
                  <span v-if="!$v.partner.imageAlt.maxLength">Image Alt must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Logo</label>
                <div class="dropzone" id="logoDropzone" ref="logoDropzone"></div>
                <div v-if="submitted && $v.partner.logo.$error" class="invalid-feedback">
                  <span v-if="!$v.partner.logo.required">Logo is required</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">{{ partner.logo }}</div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Logo Alt</label>
                <input
                  v-model.trim="partner.logoAlt"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.partner.logoAlt.$error }"
                />
                <div v-if="submitted && $v.partner.logoAlt.$error" class="invalid-feedback">
                  <span v-if="!$v.partner.logoAlt.maxLength">Logo Alt must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <el-checkbox v-model="partner.isPublished">Is Published?</el-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <el-checkbox v-model="partner.showOnHomepage">Show on Homepage</el-checkbox>
              </div>
            </div>
          </div>
          <div class="col-lg-10 col-md-9">
            <div class="row">
              <div class="form-group col-12">
                <label>Name</label>
                <input v-model.trim="partner.name" class="form-control" :class="{ 'is-invalid': submitted && $v.partner.name.$error }" />
                <div v-if="submitted && $v.partner.name.$error" class="invalid-feedback">
                  <span v-if="!$v.partner.name.required">Name is required</span>
                  <span v-if="!$v.partner.name.maxLength">Name must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Url</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">https://eurokick.com/partner/</span>
                  </div>
                  <input v-model.trim="partner.slug" class="form-control" :class="{ 'is-invalid': submitted && $v.partner.slug.$error }" />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <input type="checkbox" v-model="autoGenerateSlug" class="mr-2" />
                      <span>Auto Generate</span>
                    </span>
                  </div>
                  <div v-if="submitted && $v.partner.slug.$error" class="invalid-feedback">
                    <span v-if="!$v.partner.slug.required">Slug is required</span>
                    <span v-if="!$v.partner.slug.maxLength">Slug must be less than 255 characters</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Tab Title</label>
                <input
                  v-model.trim="partner.tabTitle"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.partner.tabTitle.$error }"
                />
                <div v-if="submitted && $v.partner.tabTitle.$error" class="invalid-feedback">
                  <span v-if="!$v.partner.tabTitle.required">Tab title is required</span>
                  <span v-if="!$v.partner.tabTitle.maxLength">Tab title must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label>LinkedIn</label>
                <input
                  v-model.trim="partner.linkedin"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.partner.linkedin.$error }"
                />
                <div v-if="submitted && $v.partner.linkedin.$error" class="invalid-feedback">
                  <span v-if="!$v.partner.linkedin.maxLength">LinkedIn must be less than 255 characters</span>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Twitter</label>
                <input
                  v-model.trim="partner.twitter"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.partner.twitter.$error }"
                />
                <div v-if="submitted && $v.partner.twitter.$error" class="invalid-feedback">
                  <span v-if="!$v.partner.twitter.maxLength">Twitter must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label>Instagram</label>
                <input
                  v-model.trim="partner.instagram"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.partner.instagram.$error }"
                />
                <div v-if="submitted && $v.partner.instagram.$error" class="invalid-feedback">
                  <span v-if="!$v.partner.instagram.maxLength">Instagram must be less than 255 characters</span>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>External Link</label>
                <input
                  v-model.trim="partner.externalLink"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.partner.externalLink.$error }"
                />
                <div v-if="submitted && $v.partner.externalLink.$error" class="invalid-feedback">
                  <span v-if="!$v.partner.externalLink.maxLength">External link must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Description</label>
                <textarea
                  v-model="partner.description"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.partner.description.$error }"
                />
                <div v-if="submitted && $v.partner.description.$error" class="invalid-feedback">
                  <span v-if="!$v.partner.description.required">Description is required</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Content</label>
                <ckeditor v-model="partner.content" :config="editorSettings.config" :editor="editorSettings.editor" />
                <div v-if="submitted && $v.partner.content.$error" class="invalid-feedback">
                  <span v-if="!$v.partner.content.required">Content is required</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Category</label>
                <select
                  v-model="partner.partnerCategoryId"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.partner.partnerCategoryId.$error }"
                >
                  <option v-for="partnerCategory in partnerCategories" :key="partnerCategory.id" :value="partnerCategory.id">
                    {{ partnerCategory.name }}
                  </option>
                </select>
                <div v-if="submitted && $v.partner.partnerCategoryId.$error" class="invalid-feedback">
                  <span v-if="!$v.partner.partnerCategoryId.required">Category is required</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Team Members</label>
                <el-select
                  v-model="partner.teamMembers"
                  multiple
                  filterable
                  class="w-100"
                  :class="{ 'is-invalid': submitted && $v.partner.teamMembers.$errors }"
                >
                  <el-option
                    v-for="teamMember in teamMembers"
                    :key="teamMember.id"
                    :label="teamMember.firstName + ' ' + teamMember.lastName"
                    :value="teamMember.id"
                  />
                </el-select>
                <div v-if="submitted && $v.partner.teamMembers.$error" class="invalid-feedback"></div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Redirect To</label>
                <input
                  v-model="partner.redirectTo"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.partner.redirectTo.$error }"
                />
                <div v-if="submitted && $v.partner.redirectTo.$error" class="invalid-feedback">
                  <span v-if="!$v.partner.redirectTo.maxLength">Redirect to must be less than 255 characters</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button v-if="isEdit" @click.prevent="handleDelete" type="button" class="btn btn-danger mr-2">Delete</button>
            <button type="button" class="btn btn-primary mr-2" @click="handlePreview">Preview</button>
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </PageCard>
      <PageCard title="Images">
        <div class="row">
          <div class="form-group col-12">
            <label>Browse Files (You can upload multiple files)</label>
            <br />
            <label><b>You can reorder images through drag&drop</b></label>
            <input type="file" @input="imagePreview" multiple />
            <button type="button" class="btn btn-sm btn-danger mt-2" @click.prevent="deleteAllImages" v-if="partner.images.length">
              Remove All
            </button>
          </div>
        </div>
        <div ref="imagesPreview">
          <VueDraggable class="row" @end="reorderImages" :animation="150">
            <div
              class="col-lg-2 col-md-3 col-sm-4 mb-3 p-1 preview-image"
              v-for="(image, index) in partner.images"
              :key="index"
              :data-index="index"
            >
              <img :src="image.url" class="img-fluid" />
              <button class="btn btn-sm btn-danger" @click.prevent="removeImage(index)">X</button>
              <div class="form-group">
                <label>Alt</label>
                <input v-model.trim="image.alt" class="form-control" />
              </div>
            </div>
          </VueDraggable>
        </div>
      </PageCard>
      <PageCard title="Milestones">
        <div class="row mb-3">
          <div class="col-lg-12 text-right">
            <div class="text-left">
              <span class="text-muted">You can reorder milestones through drag&drop</span>
            </div>
            <button v-if="partner.milestones.length" type="button" class="btn btn-danger mr-2" @click.prevent="removeAllMilestones">
              <i class="icon-trash mr-1"></i>
              Remove All
            </button>
            <button type="button" class="btn btn-primary" @click.prevent="addNewMilestone">
              <i class="icon-plus3 mr-1"></i>
              Add
            </button>
          </div>
        </div>
        <VueDraggable @end="reorderMilestones" :animation="150">
          <div class="row milestone" v-for="(milestone, index) in partner.milestones" :key="index" :data-index="index">
            <div class="form-group col-lg-11">
              <label>Title</label>
              <input v-model.trim="milestone.title" class="form-control" />
            </div>
            <div class="form-group col-lg-1">
              <label>Actions</label>
              <button type="button" class="btn btn-block btn-danger" @click.prevent="removeMilestone(index)">
                <i class="icon-trash mr-1"></i>
              </button>
            </div>
          </div>
        </VueDraggable>
      </PageCard>
      <PageCard title="Partner's Team Members">
        <div class="row mb-3">
          <div class="col-lg-12 text-right">
            <div class="text-left">
              <span class="text-muted">You can reorder members through drag&drop</span>
            </div>
            <button
              v-if="partner.partnerTeamMembers.length"
              type="button"
              class="btn btn-danger mr-2"
              @click.prevent="removeAllPartnerTeamMembers"
            >
              <i class="icon-trash mr-1"></i>
              Remove All
            </button>
            <button type="button" class="btn btn-primary" @click.prevent="addNewPartnerTeamMember">
              <i class="icon-plus3 mr-1"></i>
              Add
            </button>
          </div>
        </div>
        <VueDraggable @end="reorderPartnerTeamMembers" :animation="150">
          <div class="row partner-team-member" v-for="(teamMember, index) in partner.partnerTeamMembers" :key="index" :data-index="index">
            <div class="form-group col-lg-11">
              <label>Full Name</label>
              <input v-model.trim="teamMember.fullName" class="form-control" />
            </div>
            <div class="form-group col-lg-1">
              <label>Actions</label>
              <button type="button" class="btn btn-block btn-danger" @click.prevent="removePartnerTeamMember(index)">
                <i class="icon-trash mr-1"></i>
              </button>
            </div>
          </div>
        </VueDraggable>
      </PageCard>
      <PageCard title="Meta Tags">
        <div class="row mb-3">
          <div class="col-lg-12 text-right">
            <button v-if="partner.metaTags.length" type="button" class="btn btn-danger mr-2" @click.prevent="removeAllMetaTags">
              <i class="icon-trash mr-1"></i>
              Remove All
            </button>
            <button type="button" class="btn btn-primary" @click.prevent="addNewMetaTag">
              <i class="icon-plus3 mr-1"></i>
              Add
            </button>
          </div>
        </div>
        <div class="row" v-for="(tag, index) in partner.metaTags" :key="index">
          <div class="form-group col-lg-5">
            <label>Name</label>
            <input v-model.trim="tag.name" class="form-control" />
          </div>
          <div class="form-group col-lg-6">
            <label>Content</label>
            <input v-model.trim="tag.content" class="form-control" />
          </div>
          <div class="form-group col-lg-1">
            <label>Actions</label>
            <button type="button" class="btn btn-block btn-danger" @click.prevent="removeMetaTag(index)">
              <i class="icon-trash mr-1"></i>
            </button>
          </div>
        </div>
      </PageCard>
      <PageCard title="Additional JavaScript">
        <textarea
          v-model="partner.additionalJavaScript"
          class="form-control"
          :class="{ 'is-invalid': submitted && $v.partner.additionalJavaScript.$error }"
        ></textarea>
        <div v-if="submitted && $v.partner.additionalJavaScript.$error" class="invalid-feedback"></div>
      </PageCard>
    </form>
    <el-dialog title="Redirect" :visible.sync="showRedirectDialog" width="50%">
      <div class="row">
        <div class="form-group col-12">
          <label>Redirect To</label>
          <input v-model="redirectTo" class="form-control" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button type="button" class="btn btn-warning mr-2" @click="handleRedirect(false)">Delete without Redirect</button>
        <button type="button" class="btn btn-backoffice-primary" @click="handleRedirect(true)" v-if="redirectTo">
          Redirect and Delete
        </button>
      </span>
    </el-dialog>
  </Page>
</template>

<script>
import partnerActions from "@/store/partner/actions.js";
import { required, maxLength } from "vuelidate/lib/validators";
import Dropzone from "dropzone";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VueDraggable from "vuedraggable";
import $ from "jquery";
import httpClient from "@/libs/axios.js";
import teamMemberActions from "@/store/teamMember/actions.js";
import { uploader } from "@/plugins/ckeditorUploadAdapter.js";

let formLoader = null;
let imageDropzoneLoader = null;
let logoDropzoneLoader = null;
let imagesLoader = null;

export default {
  name: "AddOrUpdatePartner",

  components: {
    VueDraggable,
  },

  data() {
    return {
      partner: {
        name: "",
        slug: "",
        image: "",
        imageAlt: "",
        logo: "",
        logoAlt: "",
        linkedin: "",
        twitter: "",
        instagram: "",
        externalLink: "",
        content: "",
        description: "",
        tabTitle: "",
        images: [],
        milestones: [],
        partnerTeamMembers: [],
        metaTags: [],
        teamMembers: [],
        isPublished: false,
        showOnHomepage: false,
        redirectTo: "",
        redirectToParentPage: "",
        partnerCategoryId: "",
        additionalJavaScript: "",
      },

      teamMembers: [],
      submitted: false,
      autoGenerateSlug: true,
      partners: [],
      redirectTo: "",
      partnerCategories: [],
      showRedirectDialog: false,

      editorSettings: {
        editor: ClassicEditor,
        config: {
          extraPlugins: [uploader],
        },
      },
    };
  },

  async mounted() {
    formLoader = this.$loading.show({ container: this.$refs.form });
    this.teamMembers = await this.$store.dispatch(teamMemberActions.GET_ALL_TEAM_MEMBERS);
    this.partners = await this.$store.dispatch(partnerActions.GET_ALL_PARTNERS);
    this.partnerCategories = await this.$store.dispatch(partnerActions.GET_ALL_PARTNER_CATEGORIES);

    if (this.isEdit) {
      var partner = await this.$store.dispatch(partnerActions.GET_PARTNER_BY_ID, this.$route.params.id);
      partner.teamMembers = partner.teamMembers.map((teamMember) => teamMember.id);
      if (this.$slugify(partner.name) !== partner.slug) {
        this.autoGenerateSlug = false;
      }

      this.redirectTo = partner.redirectTo;
      this.partner = partner;
    }

    this.$nextTick(() => {
      this.initDropzone();
      formLoader.hide();
    });
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        formLoader.hide();
        return;
      }

      await this.$store.dispatch(partnerActions.ADD_OR_UPDATE_PARTNER, this.partner);
      formLoader.hide();
    },

    handleDelete() {
      this.$confirm("Are you sure to delete this record?", () => {
        this.showRedirectDialog = true;
      });
    },

    async handleRedirect(redirect) {
      formLoader = this.$loading.show({ container: this.$refs.form });
      if (redirect) {
        await this.$store.dispatch(partnerActions.DELETE_PARTNER, {
          id: this.partner.id,
          redirectTo: this.redirectTo,
        });
      } else {
        await this.$store.dispatch(partnerActions.DELETE_PARTNER, {
          id: this.partner.id,
        });
      }

      formLoader.hide();
      this.$router.push({ name: "Partners" });
    },

    initDropzone() {
      let imageDropzone = new Dropzone("#imageDropzone", {
        url: `${process.env.VUE_APP_BASE_API_URL}/general/upload-file?uploadType=partner`,
        maxFiles: 1,
        acceptedFiles: "image/*",
        dictDefaultMessage: "",
        addRemoveLinks: true,
        headers: {
          authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        withCredentials: true,
      });

      imageDropzone.on("addedfile", () => {
        imageDropzoneLoader = this.$loading.show({ container: this.$refs.imageDropzone });
      });

      imageDropzone.on("success", (file, response) => {
        this.partner.image = response.data;
      });

      imageDropzone.on("removedfile", () => {
        this.partner.image = "";
      });

      imageDropzone.on("complete", () => {
        imageDropzoneLoader.hide();
      });

      let logoDropzone = new Dropzone("#logoDropzone", {
        url: `${process.env.VUE_APP_BASE_API_URL}/general/upload-file?uploadType=partner`,
        maxFiles: 1,
        acceptedFiles: "image/*",
        dictDefaultMessage: "",
        addRemoveLinks: true,
        headers: {
          authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        withCredentials: true,
      });

      logoDropzone.on("addedfile", () => {
        logoDropzoneLoader = this.$loading.show({ container: this.$refs.logoDropzone });
      });

      logoDropzone.on("success", (file, response) => {
        this.partner.logo = response.data;
      });

      logoDropzone.on("removedfile", () => {
        this.partner.logo = "";
      });

      logoDropzone.on("complete", () => {
        logoDropzoneLoader.hide();
      });

      if (this.isEdit) {
        const imageMockFile = { name: "", dataURL: this.partner.image, size: 12345, type: "image/png" };

        imageDropzone.emit("addedfile", imageMockFile);
        imageDropzone.emit("success", imageMockFile, { data: this.partner.image });
        imageDropzone.emit("thumbnail", imageMockFile, this.partner.image);
        imageDropzone.emit("complete", imageMockFile);

        const logoMockFile = { name: "", dataURL: this.partner.logo, size: 12345, type: "image/png" };

        logoDropzone.emit("addedfile", logoMockFile);
        logoDropzone.emit("success", logoMockFile, { data: this.partner.logo });
        logoDropzone.emit("thumbnail", logoMockFile, this.partner.logo);
        logoDropzone.emit("complete", logoMockFile);
      }
    },

    async imagePreview(e) {
      imagesLoader = this.$loading.show({ container: this.$refs.imagesPreview });

      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        var formData = new FormData();
        formData.append("file", file);

        try {
          const { data: result } = await httpClient.post("/general/upload-file?uploadType=partner", formData);
          if (result.success) {
            this.partner.images.push({
              url: result.data,
              order: this.partner.images.length,
              alt: "",
            });
          }
        } catch {
          this.$message.error("One or more of the images could not be uploaded");
        }
      }

      e.target.value = "";
      imagesLoader.hide();
    },

    reorderImages() {
      $(".preview-image").each((order, el) => {
        const index = $(el).data("index");
        this.partner.images[index] = {
          id: this.partner.images[index].id,
          order: order,
          url: this.partner.images[index].url,
          alt: this.partner.images[index].alt,
        };
      });
    },

    deleteAllImages() {
      this.partner.images = [];
    },

    removeImage(index) {
      this.partner.images.splice(index, 1);
    },

    addNewMilestone() {
      this.partner.milestones.push({
        title: "",
        order: this.partner.milestones.length,
      });
    },

    removeAllMilestones() {
      this.partner.milestones = [];
    },

    reorderMilestones() {
      $(".milestone").each((order, el) => {
        const index = $(el).data("index");
        this.partner.milestones[index] = {
          id: this.partner.milestones[index].id,
          order: order,
          title: this.partner.milestones[index].title,
        };
      });
    },

    removeMilestone(index) {
      this.partner.milestones.splice(index, 1);
    },

    addNewPartnerTeamMember() {
      this.partner.partnerTeamMembers.push({
        name: "",
        order: this.partner.partnerTeamMembers.length,
      });
    },

    removeAllPartnerTeamMembers() {
      this.partner.partnerTeamMembers = [];
    },

    removePartnerTeamMember(index) {
      this.partner.partnerTeamMembers.splice(index, 1);
    },

    reorderPartnerTeamMembers() {
      $(".partner-team-member").each((order, el) => {
        const index = $(el).data("index");
        this.partner.partnerTeamMembers[index] = {
          id: this.partner.partnerTeamMembers[index].id,
          order: order,
          name: this.partner.partnerTeamMembers[index].name,
        };
      });
    },

    addNewMetaTag() {
      this.partner.metaTags.push({
        name: "",
        content: "",
      });
    },

    removeAllMetaTags() {
      this.partner.metaTags = [];
    },

    removeMetaTag(index) {
      this.partner.metaTags.splice(index, 1);
    },

    handlePreview() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$message.error("Please fill all required fields");
        return;
      }

      var params = this.partner;
      params.page = "partnerDetails";
      params.partnerTeamMembers = this.partner.partnerTeamMembers.map((member) => member.fullName);
      params.milestones = this.partner.milestones.map((milestone) => milestone.title);
      params.partners = this.teamMembers.filter((x) => this.partner.teamMembers.includes(x.id)).map((x) => x.firstName + " " + x.lastName);
      const paramsObj = new URLSearchParams(params).toString();
      // window.open(`/preview?${paramsObj.toString()}`, "_blank");
      window.open(`/preview?${paramsObj.toString()}`, "", "width=1000,height=800");
    },
  },

  computed: {
    isEdit() {
      return this.$router.currentRoute.name === "EditPartner";
    },
    partnerSelectList() {
      if (this.isEdit) {
        return this.partners.filter((partner) => partner.id !== this.partner.id);
      }

      return this.partners;
    },
  },

  watch: {
    "partner.name": function () {
      if (!this.autoGenerateSlug) {
        return;
      }

      this.partner.slug = this.$slugify(this.partner.name);
    },
  },

  validations() {
    return {
      partner: {
        name: { required, maxLength: maxLength(255) },
        slug: { required, maxLength: maxLength(255) },
        image: { required },
        imageAlt: { maxLength: maxLength(255) },
        logo: { required },
        logoAlt: { maxLength: maxLength(255) },
        linkedin: { maxLength: maxLength(255) },
        twitter: { maxLength: maxLength(255) },
        instagram: { maxLength: maxLength(255) },
        externalLink: { maxLength: maxLength(255) },
        tabTitle: { required, maxLength: maxLength(255) },
        content: { required },
        description: { required },
        teamMembers: {},
        redirectTo: { maxLength: maxLength(255) },
        partnerCategoryId: { required },
        additionalJavaScript: {},
      },
    };
  },

  metaInfo() {
    return {
      title: "Add & Update Partner",
    };
  },
};
</script>

<style scoped>
.preview-image,
.milestone,
.partner-team-member {
  cursor: move;
}
.preview-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}
.preview-image button {
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>
