import actionTypes from "./actions.js";
import mutationTypes from "./mutations.js";
import httpClient from "@/libs/axios.js";
import router from "@/router/index.js";

const state = {
  homePageBlogPosts: [],
  blogPosts: [],
  blogPostWriters: [],
};

const mutations = {
  [mutationTypes.SET_HOME_PAGE_BLOG_POSTS](state, payload) {
    state.homePageBlogPosts = payload;
  },
  [mutationTypes.SET_BLOG_POSTS](state, payload) {
    state.blogPosts = payload;
  },
  [mutationTypes.SET_BLOG_POST_WRITERS](state, payload) {
    state.blogPostWriters = payload;
  },
};

const actions = {
  async [actionTypes.GET_ALL_BLOG_POSTS]({ commit }) {
    const { data: result } = await httpClient.get("/blog");
    if (result.success) {
      commit(mutationTypes.SET_BLOG_POSTS, result.data);
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.GET_BLOG_POST_BY_ID]({ commit }, id) {
    const { data: result } = await httpClient.get(`/blog/${id}`);
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.ADD_OR_UPDATE_BLOG_POST]({ commit }, blogPost) {
    const { data: result } = await httpClient.post("/blog", blogPost);
    if (result.success) {
      router.push({ name: "BlogPosts" });
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.DELETE_BLOG_POST]({ commit }, { id, redirectTo }) {
    await httpClient.post(`/blog/delete/${id}`, { redirectTo });
  },
  async [actionTypes.GET_ALL_BLOG_POST_CATEGORIES]() {
    const { data: result } = await httpClient.get("/blog/category");
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.GET_BLOG_POST_CATEGORY_BY_ID]({ commit }, id) {
    const { data: result } = await httpClient.get(`/blog/category/${id}`);
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.ADD_OR_UPDATE_BLOG_POST_CATEGORY]({ commit }, blogPostCategory) {
    const { data: result } = await httpClient.post("/blog/category", blogPostCategory);
    if (result.success) {
      router.push({ name: "BlogPostCategories" });
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.DELETE_BLOG_POST_CATEGORY]({ commit }, id) {
    await httpClient.delete(`/blog/category/${id}`);
  },
  async [actionTypes.GET_ALL_BLOG_POST_SUBSCRIBERS]() {
    const { data: result } = await httpClient.get("/blog/subscriber");
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.DELETE_BLOG_POST_SUBSCRIBER]({ commit }, id) {
    await httpClient.delete(`/blog/subscriber/${id}`);
  },
  async [actionTypes.GET_ALL_BLOG_POST_WRITERS]({ commit }) {
    const { data: result } = await httpClient.get("/blog/writer");
    if (result.success) {
      commit(mutationTypes.SET_BLOG_POST_WRITERS, result.data);
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.GET_BLOG_POST_WRITER_BY_ID]({ commit }, id) {
    const { data: result } = await httpClient.get(`/blog/writer/${id}`);
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.ADD_OR_UPDATE_BLOG_POST_WRITER]({ commit }, blogPostWriter) {
    const { data: result } = await httpClient.post("/blog/writer", blogPostWriter);
    if (result.success) {
      router.push({ name: "BlogPostWriters" });
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.DELETE_BLOG_POST_WRITER]({ commit }, id) {
    await httpClient.delete(`/blog/writer/${id}`);
  },
  async [actionTypes.GET_HOME_PAGE_BLOG_POSTS]({ commit }) {
    const { data: result } = await httpClient.get("/blog/home");
    if (result.success) {
      commit(mutationTypes.SET_HOME_PAGE_BLOG_POSTS, result.data);
    }
  },
};

const getters = {
  homePageBlogPosts: (state) => state.homePageBlogPosts,
  blogPosts: (state) => state.blogPosts,
  blogPostWriters: (state) => state.blogPostWriters,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
