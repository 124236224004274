import actionTypes from "./actions.js";
import mutationTypes from "./mutations.js";
import httpClient from "../../libs/axios.js";
import router from "@/router/index.js";

const state = {
  partners: [],
  homePagePartners: [],
};

const mutations = {
  [mutationTypes.SET_HOME_PAGE_PARTNERS](state, payload) {
    state.homePagePartners = payload;
  },
  [mutationTypes.SET_PARTNERS](state, payload) {
    state.partners = payload;
  },
};

const actions = {
  async [actionTypes.GET_ALL_PARTNERS]({ commit }) {
    const { data: result } = await httpClient.get("/partner");
    if (result.success) {
      commit(mutationTypes.SET_PARTNERS, result.data);
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.GET_PARTNER_BY_ID]({ commit }, id) {
    const { data: result } = await httpClient.get(`/partner/${id}`);
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.ADD_OR_UPDATE_PARTNER]({ commit }, partner) {
    const { data: result } = await httpClient.post("/partner", partner);
    if (result.success) {
      router.push({ name: "Partners" });
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.DELETE_PARTNER]({ commit }, { id, redirectTo }) {
    await httpClient.post(`/partner/delete/${id}`, { redirectTo });
  },
  async [actionTypes.GET_HOME_PAGE_PARTNERS]({ commit }) {
    const { data: result } = await httpClient.get("/partner/home");
    if (result.success) {
      commit(mutationTypes.SET_HOME_PAGE_PARTNERS, result.data);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.GET_ALL_PARTNER_CATEGORIES]({ commit }) {
    const { data: result } = await httpClient.get("/partner/category");
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.GET_PARTNER_CATEGORY_BY_ID]({ commit }, id) {
    const { data: result } = await httpClient.get(`/partner/category/${id}`);
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.ADD_OR_UPDATE_PARTNER_CATEGORY]({ commit }, partnerCategory) {
    const { data: result } = await httpClient.post("/partner/category", partnerCategory);
    if (result.success) {
      router.push({ name: "PartnerCategories" });
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.DELETE_PARTNER_CATEGORY]({ commit }, id) {
    await httpClient.delete(`/partner/category/${id}`);
  },
};

const getters = {
  homePagePartners: (state) => state.homePagePartners,
  partners: (state) => state.partners,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
