<template>
  <Page :links="[{ to: 'CreatePartner', text: 'New Record', iconName: 'icon-plus3' }]">
    <PageCard title="Partners">
      <el-table :data="paginatedData" ref="table">
        <el-table-column prop="name" label="Name" />
        <el-table-column prop="createdAt" label="Created Date" sortable width="150px" align="center">
          <template slot-scope="scope">
            {{ $formatDate(scope.row.createdAt) }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="220px">
          <template slot="header">
            <input class="form-control" v-model="searchTerm" placeholder="Type to search" />
          </template>
          <template slot-scope="scope">
            <el-button @click.prevent="handleEdit(scope.row)" icon="icon-pencil" size="mini" class="mr-2" />
            <el-button @click.prevent="handleDelete(scope.row)" icon="icon-trash" size="mini" type="danger" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="d-flex justify-content-center mt-3"
        :current-page="currentPage"
        :page-size="pageSize"
        @current-change="(val) => (currentPage = val)"
        @size-change="(val) => (pageSize = val)"
        :page-sizes="[5, 15, 30, 50, 100]"
        background
        layout="sizes,prev,pager,next"
        :total="filteredData.length"
      />
    </PageCard>
    <el-dialog title="Redirect" :visible.sync="showRedirectDialog" width="50%">
      <div class="row">
        <div class="form-group col-12">
          <label>Redirect To</label>
          <input v-model="redirectTo" class="form-control" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button type="button" class="btn btn-warning mr-2" @click="handleRedirect(false)">Delete without Redirect</button>
        <button type="button" class="btn btn-backoffice-primary" @click="handleRedirect(true)" v-if="redirectTo">
          Redirect and Delete
        </button>
      </span>
    </el-dialog>
  </Page>
</template>

<script>
import partnerActions from "@/store/partner/actions.js";

let tableLoader = null;

export default {
  name: "Partners",

  data() {
    return {
      partners: [],
      searchTerm: "",
      currentPage: 1,
      pageSize: 15,
      redirectTo: "",
      selectedPartner: null,
      showRedirectDialog: false,
    };
  },

  async mounted() {
    tableLoader = this.$loading.show({ container: this.$refs.table.$el });
    this.partners = await this.$store.dispatch(partnerActions.GET_ALL_PARTNERS);
    tableLoader.hide();
  },

  methods: {
    handleEdit(partner) {
      this.$router.push({ name: "EditPartner", params: { id: partner.id } });
    },

    handleDelete(partner) {
      this.$confirm("Are you sure to delete this record?", () => {
        this.selectedPartner = partner;
        this.redirectTo = partner.redirectTo;
        this.showRedirectDialog = true;
      });
    },

    async handleRedirect(redirect) {
      tableLoader = this.$loading.show({ container: this.$refs.table.$el });
      if (redirect) {
        await this.$store.dispatch(partnerActions.DELETE_PARTNER, {
          id: this.selectedPartner.id,
          redirectTo: this.redirectTo,
        });
      } else {
        await this.$store.dispatch(partnerActions.DELETE_PARTNER, {
          id: this.selectedPartner.id,
        });
      }

      this.partners.splice(this.partners.indexOf(this.selectedPartner), 1);
      tableLoader.hide();
      this.showRedirectDialog = false;
      this.redirectTo = "";
    },
  },

  computed: {
    filteredData() {
      return this.partners.filter((x) => !this.searchTerm || x.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
    paginatedData() {
      return this.filteredData.slice(this.pageSize * this.currentPage - this.pageSize, this.pageSize * this.currentPage);
    },
    partnerSelectList() {
      if (this.selectedPartner) {
        return this.partners.filter((x) => x.id !== this.selectedPartner.id);
      }

      return this.partners;
    },
  },

  metaInfo() {
    return {
      title: "Partners",
    };
  },
};
</script>
