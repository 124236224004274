<template>
  <div class="navbar navbar-expand-md navbar-dark">
    <div class="navbar-brand wmin-200" style="padding: 0px; margin: 0px">
      <router-link :to="{ name: 'Home' }" class="d-inline-block">
        <h4 class="text-light" style="letter-spacing: 1px; margin-top: 10px">
          <img src="@/assets/logo.svg" alt="logo" class="img-fluid" style="height: 30px;">
        </h4>
      </router-link>
    </div>
    <div class="d-md-none">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
        <i class="icon-tree5"></i>
      </button>
      <button class="navbar-toggler sidebar-mobile-main-toggle" type="button">
        <i class="icon-paragraph-justify3"></i>
      </button>
    </div>
    <div class="collapse navbar-collapse" id="navbar-mobile">
      <ul class="navbar-nav">
        <li class="nav-item ml-3" @click.prevent="sidebarToggle">
          <a class="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block">
            <i class="icon-paragraph-justify3"></i>
          </a>
        </li>
      </ul>
      <span class="badge bg-success-400 ml-md-auto mr-md-3" v-if="user">{{ user.role }}</span>
      <ul class="navbar-nav">
        <li class="nav-item dropdown dropdown-user">
          <a class="navbar-nav-link d-flex align-items-center dropdown-toggle" data-toggle="dropdown" v-if="user">
            <span>{{ user.firstName }} {{ user.lastName }}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <router-link :to="{ name: 'UpdateProfile' }" class="dropdown-item"><i class="icon-switch2"></i>Account</router-link>
            <router-link :to="{ name: 'ChangePassword' }" class="dropdown-item"><i class="icon-switch2"></i>Change Password</router-link>
            <a @click.prevent="logout" class="dropdown-item"><i class="icon-switch2"></i>Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import accountActions from "@/store/account/actions.js";

export default {
  name: "Header",

  methods: {
    logout() {
      this.$store.dispatch(accountActions.LOGOUT);
    },

    sidebarToggle() {
      const bodyEl = document.getElementsByTagName("body")[0];
      bodyEl.classList.toggle("sidebar-xs");
    },
  },

  computed: {
    ...mapGetters(["user"]),
    userRole() {
      return "Admin";
    },
  },
};
</script>

<style scoped>
.navbar {
  background: linear-gradient(111.56deg, #1b2029 11.08%, rgb(33, 62, 116) 99.09%);
}
</style>
