<template>
  <Page>
    <form @submit.prevent="handleSubmit" ref="form">
      <PageCard title="Global Scripts">
        <div class="row">
          <div class="form-group col-12">
            <textarea v-model="pageScripts.content" class="form-control"></textarea>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </PageCard>
    </form>
  </Page>
</template>

<script>
import pageContentActions from "@/store/pageContent/actions.js";

let formLoader = null;

export default {
  name: "EditGlobalScripts",

  data() {
    return {
      pageScripts: {
        content: "",
      },
      submitted: false,
    };
  },

  async mounted() {
    formLoader = this.$loading.show({ container: this.$refs.form });
    this.pageScripts = await this.$store.dispatch(pageContentActions.GET_GLOBAL_SCRIPTS);
    formLoader.hide();
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      await this.$store.dispatch(pageContentActions.UPDATE_GLOBAL_SCRIPTS, this.pageScripts);
      formLoader.hide();
    },
  },

  metaInfo() {
    return {
      title: "Edit Global Scripts",
    };
  },
};
</script>
