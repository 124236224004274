import * as enums from "@/helpers/enum.js";

const state = {};

const mutations = {};

const actions = {};

const getters = {
  writerTypes() {
    return Object.keys(enums.WriterType).map((key) => {
      return {
        key: key,
        value: enums.WriterType[key].value,
        displayName: enums.WriterType[key].displayName,
      };
    });
  },
  contactTypes() {
    return Object.keys(enums.ContactType).map((key) => {
      return {
        key: key,
        value: enums.ContactType[key].value,
        displayName: enums.ContactType[key].displayName,
      };
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
