export default {
  GET_ALL_PARTNERS: "GET_ALL_PARTNERS",
  GET_PARTNER_BY_ID: "GET_PARTNER_BY_ID",
  ADD_OR_UPDATE_PARTNER: "ADD_OR_UPDATE_PARTNER",
  DELETE_PARTNER: "DELETE_PARTNER",
  GET_HOME_PAGE_PARTNERS: "GET_HOME_PAGE_PARTNERS",
  GET_ALL_PARTNER_CATEGORIES: "GET_ALL_PARTNER_CATEGORIES",
  GET_PARTNER_CATEGORY_BY_ID: "GET_PARTNER_CATEGORY_BY_ID",
  ADD_OR_UPDATE_PARTNER_CATEGORY: "ADD_OR_UPDATE_PARTNER_CATEGORY",
  DELETE_PARTNER_CATEGORY: "DELETE_PARTNER_CATEGORY",
};
