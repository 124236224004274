import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import AuthorizedLayout from "@/components/layouts/AuthorizedLayout.vue";
import Login from "@/views/authentication/Login.vue";
import UpdateProfile from "@/views/account/UpdateProfile.vue";
import ChangePassword from "@/views/account/ChangePassword.vue";
import BlogPosts from "@/views/blog/Index.vue";
import AddOrUpdateBlogPost from "@/views/blog/AddOrUpdate.vue";
import BlogPostCategories from "@/views/blog/Categories.vue";
import AddOrUpdateBlogPostCategory from "@/views/blog/AddOrUpdateCategory.vue";
import NewsletterSubscribers from "@/views/blog/NewsletterSubscribers.vue";
import Offices from "@/views/office/Index.vue";
import AddOrUpdateOffice from "@/views/office/AddOrUpdate.vue";
import Partners from "@/views/partner/Index.vue";
import AddOrUpdatePartner from "@/views/partner/AddOrUpdate.vue";
import TeamMembers from "@/views/teamMember/Index.vue";
import AddOrUpdateTeamMember from "@/views/teamMember/AddOrUpdate.vue";
import BlogPostWriters from "@/views/blog/Writers.vue";
import AddOrUpdateBlogPostWriter from "@/views/blog/AddOrUpdateWriter.vue";
import InvestmentCategories from "@/views/investment/Index.vue";
import AddOrUpdateInvestmentCategory from "@/views/investment/AddOrUpdate.vue";
import Contacts from "@/views/contact/Index.vue";
import ContactDetails from "@/views/contact/Details.vue";
import HomePageContent from "@/views/pageContent/HomePageContent.vue";
import FoundersPageContent from "@/views/pageContent/FoundersPageContent.vue";
import InsightsPageContent from "@/views/pageContent/InsightsPageContent.vue";
import ContactPageContent from "@/views/pageContent/ContactPageContent.vue";
import PartnersPageContent from "@/views/pageContent/PartnersPageContent.vue";
import AboutCompany from "@/views/company/About.vue";
import EditGlobalMetaTags from "@/views/pageContent/EditGlobalMetaTags.vue";
import EditGlobalScripts from "@/views/pageContent/EditGlobalScripts.vue";
import Users from "@/views/user/Index.vue";
import AddOrUpdateUser from "@/views/user/AddOrUpdate.vue";
import Redirects from "@/views/redirect/Index.vue";
import AddOrUpdateRedirect from "@/views/redirect/AddOrUpdate.vue";
import PartnerCategories from "@/views/partner/Categories.vue";
import AddOrUpdatePartnerCategory from "@/views/partner/AddOrUpdateCategory.vue";
import RobotsTxt from "@/views/seoSettings/RobotsTxt.vue";
import Sitemap from "@/views/seoSettings/Sitemap.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/preview",
    name: "Preview",
    component: () => import("@/views/Preview.vue"),
  },
  {
    path: "",
    component: AuthorizedLayout,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/account/update-profile",
        name: "UpdateProfile",
        component: UpdateProfile,
      },
      {
        path: "/account/change-password",
        name: "ChangePassword",
        component: ChangePassword,
      },
      {
        path: "/blog",
        name: "BlogPosts",
        component: BlogPosts,
      },
      {
        path: "/blog/new",
        name: "CreateBlogPost",
        component: AddOrUpdateBlogPost,
      },
      {
        path: "/blog/edit/:id",
        name: "EditBlogPost",
        component: AddOrUpdateBlogPost,
      },
      {
        path: "/blog/category",
        name: "BlogPostCategories",
        component: BlogPostCategories,
      },
      {
        path: "/blog/category/new",
        name: "CreateBlogPostCategory",
        component: AddOrUpdateBlogPostCategory,
      },
      {
        path: "/blog/category/edit/:id",
        name: "EditBlogPostCategory",
        component: AddOrUpdateBlogPostCategory,
      },
      {
        path: "/blog/writer",
        name: "BlogPostWriters",
        component: BlogPostWriters,
      },
      {
        path: "/blog/writer/new",
        name: "CreateBlogPostWriter",
        component: AddOrUpdateBlogPostWriter,
      },
      {
        path: "/blog/writer/edit/:id",
        name: "EditBlogPostWriter",
        component: AddOrUpdateBlogPostWriter,
      },
      {
        path: "/blog/category/subscribers",
        name: "NewsletterSubscribers",
        component: NewsletterSubscribers,
      },
      {
        path: "/office",
        name: "Offices",
        component: Offices,
      },
      {
        path: "/office/new",
        name: "CreateOffice",
        component: AddOrUpdateOffice,
      },
      {
        path: "/office/edit/:id",
        name: "EditOffice",
        component: AddOrUpdateOffice,
      },
      {
        path: "/partner/category",
        name: "PartnerCategories",
        component: PartnerCategories,
      },
      {
        path: "/partner/category/new",
        name: "CreatePartnerCategory",
        component: AddOrUpdatePartnerCategory,
      },
      {
        path: "/partner/category/edit/:id",
        name: "EditPartnerCategory",
        component: AddOrUpdatePartnerCategory,
      },
      {
        path: "/partner",
        name: "Partners",
        component: Partners,
      },
      {
        path: "/partner/new",
        name: "CreatePartner",
        component: AddOrUpdatePartner,
      },
      {
        path: "/partner/edit/:id",
        name: "EditPartner",
        component: AddOrUpdatePartner,
      },
      {
        path: "/team-member",
        name: "TeamMembers",
        component: TeamMembers,
      },
      {
        path: "/team-member/new",
        name: "CreateTeamMember",
        component: AddOrUpdateTeamMember,
      },
      {
        path: "/team-member/edit/:id",
        name: "EditTeamMember",
        component: AddOrUpdateTeamMember,
      },
      {
        path: "/investment/category",
        name: "InvestmentCategories",
        component: InvestmentCategories,
      },
      {
        path: "/investment/category/new",
        name: "CreateInvestmentCategory",
        component: AddOrUpdateInvestmentCategory,
      },
      {
        path: "/investment/category/edit/:id",
        name: "EditInvestmentCategory",
        component: AddOrUpdateInvestmentCategory,
      },
      {
        path: "/contact",
        name: "Contacts",
        component: Contacts,
      },
      {
        path: "/contact/edit/:id",
        name: "EditContact",
        component: ContactDetails,
      },
      {
        path: "/page-content/home",
        name: "EditHomePageContent",
        component: HomePageContent,
      },
      {
        path: "/page-content/founders",
        name: "EditFoundersPageContent",
        component: FoundersPageContent,
      },
      {
        path: "/page-content/insights",
        name: "EditInsightsPageContent",
        component: InsightsPageContent,
      },
      {
        path: "/page-content/contact",
        name: "EditContactPageContent",
        component: ContactPageContent,
      },
      {
        path: "/page-content/partners",
        name: "EditPartnersPageContent",
        component: PartnersPageContent,
      },
      {
        path: "/company/about",
        name: "EditAboutCompanyContent",
        component: AboutCompany,
      },
      {
        path: "/page-content/global-meta-tags",
        name: "EditGlobalMetaTags",
        component: EditGlobalMetaTags,
      },
      {
        path: "/page-content/global-scripts",
        name: "EditGlobalScripts",
        component: EditGlobalScripts,
      },
      {
        path: "/user",
        name: "Users",
        component: Users,
      },
      {
        path: "/user/new",
        name: "CreateUser",
        component: AddOrUpdateUser,
      },
      {
        path: "/user/edit/:id",
        name: "EditUser",
        component: AddOrUpdateUser,
      },
      {
        path: "/redirects",
        name: "Redirects",
        component: Redirects,
      },
      {
        path: "/redirect/new",
        name: "CreateRedirect",
        component: AddOrUpdateRedirect,
      },
      {
        path: "/redirect/edit/:id",
        name: "EditRedirect",
        component: AddOrUpdateRedirect,
      },
      {
        path: "/seo-settings/robots-txt",
        name: "EditRobotsTxt",
        component: RobotsTxt,
      },
      {
        path: "/seo-settings/sitemap",
        name: "Sitemap",
        component: Sitemap,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
