<template>
  <li class="nav-item nav-item-submenu" :class="{ 'nav-item-open': isOpen }">
    <a href="#" class="nav-link">
      <i :class="icon" data-popup="tooltip" :title="text"></i>
      <span>{{ text }}</span>
    </a>
    <ul class="nav nav-group-sub" :style="{ display: isOpen ? 'block' : 'none' }">
      <SidenavItem v-for="route in routes" :key="route.name" :to="route.name" :text="route.text" :icon="route.icon" />
    </ul>
  </li>
</template>

<script>
import SidenavItem from "@/components/common/sidenav/SidenavItem.vue";

export default {
  name: "SidenavDropdown",

  components: {
    SidenavItem,
  },

  props: {
    icon: {
      type: String,
      default: "icon-home",
    },
    text: {
      type: String,
      default: "Home",
    },
    to: {
      type: String,
      default: "/",
    },
    routes: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    isOpen() {
      return this.routes.some((r) => r.name === this.$route.name);
    },
  },
};
</script>
