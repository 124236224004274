<template>
  <div class="navbar navbar-expand-md navbar-light navbar-sticky mb-4">
    <div class="text-center d-md-none w-100">
      <button type="button" class="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-demo2-mobile">
        <i class="icon-menu7 mr-2"></i>
        Menu
      </button>
    </div>
    <div class="navbar-collapse collapse" id="navbar-demo2-mobile">
      <span class="navbar-text" v-if="user">
        <i class="icon-user-check mr-2"></i>
        You logged in with <router-link class="text-primary" :to="{ name: 'UpdateProfile' }">{{ user.email }}</router-link
        >.
      </span>
      <ul class="navbar-nav ml-md-auto">
        <li class="nav-item" v-for="(link, index) in links" :key="index">
          <router-link :to="{ name: link.to }" class="navbar-nav-link">
            {{ link.text }}
            <i class="ml-1" :class="link.iconName"></i>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HeaderMenu",

  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
