<template>
  <Page :links="[{ to: 'InvestmentCategories', text: 'Back to List', iconName: 'icon-arrow-right6' }]">
    <PageCard title="Add & Update Investment Category">
      <form method="post" @submit.prevent="handleSubmit" ref="form">
        <div class="row">
          <div class="form-group col-12">
            <label>Name</label>
            <input
              v-model.trim="investmentCategory.name"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.investmentCategory.name.$error }"
            />
            <div v-if="submitted && $v.investmentCategory.name.$error" class="invalid-feedback">
              <span v-if="!$v.investmentCategory.name.required">Name is required</span>
              <span v-if="!$v.investmentCategory.name.maxLength">Name must be less than 255 characters</span>
            </div>
          </div>
          <div class="form-group col-12">
            <label>Team Members</label>
            <el-select
              v-model="investmentCategory.teamMembers"
              multiple
              filterable
              class="w-100"
              :class="{ 'is-invalid': submitted && $v.investmentCategory.teamMembers.$error }"
            >
              <el-option
                v-for="member in teamMembers"
                :key="member.id"
                :label="member.firstName + ' ' + member.lastName"
                :value="member.id"
              />
            </el-select>
            <div v-if="submitted && $v.investmentCategory.teamMembers.$error" class="invalid-feedback"></div>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button v-if="isEdit" @click.prevent="handleDelete" type="button" class="btn btn-danger mr-2">Delete</button>
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </form>
    </PageCard>
  </Page>
</template>

<script>
import investmentActions from "@/store/investment/actions.js";
import { required, maxLength } from "vuelidate/lib/validators";
import teamMemberActions from "@/store/teamMember/actions.js";

let formLoader = null;

export default {
  name: "AddOrUpdateInvestmentCategory",

  data() {
    return {
      investmentCategory: {
        name: "",
        teamMembers: [],
      },

      teamMembers: [],
      submitted: false,
    };
  },

  async mounted() {
    formLoader = this.$loading.show({ container: this.$refs.form });
    this.teamMembers = await this.$store.dispatch(teamMemberActions.GET_ALL_TEAM_MEMBERS);

    if (this.isEdit) {
      var investmentCategory = await this.$store.dispatch(investmentActions.GET_INVESTMENT_CATEGORY_BY_ID, this.$route.params.id);
      investmentCategory.teamMembers = investmentCategory.teamMembers.map((teamMember) => teamMember.id);

      this.investmentCategory = investmentCategory;
    }

    formLoader.hide();
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        formLoader.hide();
        return;
      }

      await this.$store.dispatch(investmentActions.ADD_OR_UPDATE_INVESTMENT_CATEGORY, this.investmentCategory);
      formLoader.hide();
    },

    handleDelete() {
      this.$confirm("Are you sure to delete this record?", async () => {
        formLoader = this.$loading.show({ container: this.$refs.form });
        await this.$store.dispatch(investmentActions.DELETE_INVESTMENT_CATEGORY, this.investmentCategory.id);
        formLoader.hide();
        this.$router.push({ name: "InvestmentCategories" });
      });
    },
  },

  computed: {
    isEdit() {
      return this.$router.currentRoute.name === "EditInvestmentCategory";
    },
  },

  validations() {
    return {
      investmentCategory: {
        name: { required, maxLength: maxLength(255) },
        teamMembers: {},
      },
    };
  },

  metaInfo() {
    return {
      title: "Add & Update Investment Category",
    };
  },
};
</script>
