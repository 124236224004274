<template>
  <Page>
    <PageCard title="Home"></PageCard>
  </Page>
</template>

<script>
export default {
  name: "Home",

  mounted() {
    this.$router.push({ name: "BlogPosts" });
  },
};
</script>
