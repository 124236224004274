<template>
  <li class="nav-item-header mt-0">
    <div class="text-uppercase font-size-xs line-height-xs">{{ text }}</div>
    <i class="icon-menu" :title="text"></i>
  </li>
</template>

<script>
export default {
  name: "SidenavDivider",

  props: {
    text: {
      type: String,
      default: "Home",
      required: true,
    },
  },
};
</script>
