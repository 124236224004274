<template>
  <Page>
    <form @submit.prevent="handleSubmit" ref="form">
      <PageCard title="Sitemap">
        <div class="row" v-for="(url, index) in sitemapUrls" :key="index">
          <div class="form-group col-5">
            <label>Loc</label>
            <el-input v-model="url.loc" />
          </div>
          <div class="form-group col-3">
            <label>Last Mod</label>
            <el-date-picker v-model="url.lastmod" type="datetime" class="w-100" />
          </div>
          <div class="form-group col-2">
            <label>Change Freg</label>
            <el-select v-model="url.changefreq">
              <el-option value="always" />
              <el-option value="hourly" />
              <el-option value="daily" />
              <el-option value="weekly" />
              <el-option value="monthly" />
              <el-option value="yearly" />
              <el-option value="never" />
            </el-select>
          </div>
          <div class="form-group col-1">
            <label>priority</label>
            <el-input v-model.trim="url.priority" />
          </div>
          <div class="form-group col-1">
            <label>Actions</label>
            <el-button type="danger" class="btn btn-block btn-danger" @click.prevent="removeSitemapUrl(index)">
              <i class="icon-trash mr-1"></i>
            </el-button>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button type="button" class="btn btn-primary" @click="addNewSitemapUrl">
              <i class="icon-plus3 mr-1"></i>
              Add New
            </button>
          </div>
        </div>
        <div class="row text-right mt-5">
          <div class="col-12">
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </PageCard>
    </form>
  </Page>
</template>

<script>
import seoActions from "@/store/seo/actions.js";

let formLoader = null;

export default {
  name: "AddOrUpdateSitemap",

  data() {
    return {
      sitemapUrls: [],
      teamMembers: [],
      blogPosts: [],
      partners: [],
      submitted: false,
    };
  },

  async mounted() {
    formLoader = this.$loading.show({ container: this.$refs.form });
    this.sitemapUrls = await this.$store.dispatch(seoActions.GET_SITEMAP);
    formLoader.hide();
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      await this.$store.dispatch(seoActions.UPDATE_SITEMAP, { sitemapUrls: this.sitemapUrls });
      formLoader.hide();
    },

    removeSitemapUrl(index) {
      this.sitemapUrls.splice(index, 1);
    },

    addNewSitemapUrl() {
      this.sitemapUrls.push({
        loc: "https://www.eurokick.com/",
        lastmod: null,
        changefreq: "daily",
        priority: 0.8,
      });
    },
  },

  computed: {
    isEdit() {
      return this.$router.currentRoute.name === "EditSitemap";
    },
  },

  metaInfo() {
    return {
      title: "Add & Update Sitemap",
    };
  },
};
</script>
