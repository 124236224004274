import actionTypes from "./actions.js";
import httpClient from "@/libs/axios.js";
import router from "@/router/index.js";

const state = {};

const mutations = {};

const actions = {
  async [actionTypes.GET_ALL_OFFICES]() {
    const { data: result } = await httpClient.get("/office");
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.GET_OFFICE_BY_ID]({ commit }, id) {
    const { data: result } = await httpClient.get(`/office/${id}`);
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.ADD_OR_UPDATE_OFFICE]({ commit }, office) {
    const { data: result } = await httpClient.post("/office", office);
    if (result.success) {
      router.push({ name: "Offices" });
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.DELETE_OFFICE]({ commit }, id) {
    await httpClient.delete(`/office/${id}`);
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
