<template>
  <Page :links="[{ to: 'PartnerCategories', text: 'Back to List', iconName: 'icon-arrow-right6' }]">
    <PageCard title="Add & Update Partner Category">
      <form method="post" @submit.prevent="handleSubmit" ref="form">
        <div class="row">
          <div class="form-group col-12">
            <label>Name</label>
            <input
              v-model.trim="partnerCategory.name"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.partnerCategory.name.$error }"
            />
            <div v-if="submitted && $v.partnerCategory.name.$error" class="invalid-feedback">
              <span v-if="!$v.partnerCategory.name.required">Name is required</span>
              <span v-if="!$v.partnerCategory.name.maxLength">Name must be less than 255 characters</span>
            </div>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button v-if="isEdit" @click.prevent="handleDelete" type="button" class="btn btn-danger mr-2">Delete</button>
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </form>
    </PageCard>
  </Page>
</template>

<script>
import partnerActions from "@/store/partner/actions.js";
import { required, maxLength } from "vuelidate/lib/validators";

let formLoader = null;

export default {
  name: "AddOrUpdatePartnerCategory",

  data() {
    return {
      partnerCategory: {
        name: "",
      },

      submitted: false,
    };
  },

  async mounted() {
    if (this.isEdit) {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.partnerCategory = await this.$store.dispatch(partnerActions.GET_PARTNER_CATEGORY_BY_ID, this.$route.params.id);
      formLoader.hide();
    }
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        formLoader.hide();
        return;
      }

      await this.$store.dispatch(partnerActions.ADD_OR_UPDATE_PARTNER_CATEGORY, this.partnerCategory);
      formLoader.hide();
    },

    handleDelete() {
      this.$confirm("Are you sure to delete this record?", async () => {
        formLoader = this.$loading.show({ container: this.$refs.form });
        await this.$store.dispatch(partnerActions.DELETE_PARTNER_CATEGORY, this.partnerCategory.id);
        formLoader.hide();
        this.$router.push({ name: "PartnerCategories" });
      });
    },
  },

  computed: {
    isEdit() {
      return this.$router.currentRoute.name === "EditPartnerCategory";
    },
  },

  validations() {
    return {
      partnerCategory: {
        name: { required, maxLength: maxLength(255) },
      },
    };
  },
};
</script>
