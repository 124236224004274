<template>
  <Page :links="[{ to: 'TeamMembers', text: 'Back to List', iconName: 'icon-arrow-right6' }]">
    <form method="post" @submit.prevent="handleSubmit" ref="form">
      <PageCard title="Add & Update Team Member">
        <div class="row">
          <div class="col-md-3">
            <div class="row">
              <div class="form-group col-12">
                <label>Image</label>
                <div class="dropzone" id="imageDropzone" ref="imageDropzone"></div>
                <div v-if="submitted && $v.teamMember.image.$error" class="invalid-feedback">
                  <span v-if="!$v.teamMember.image.required">Image is required</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Image Alt</label>
                <input
                  v-model.trim="teamMember.imageAlt"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.teamMember.imageAlt.$error }"
                />
                <div v-if="submitted && $v.teamMember.imageAlt.$error" class="invalid-feedback">
                  <span v-if="!$v.teamMember.imageAlt.maxLength">Image Alt must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <el-checkbox v-model="teamMember.isPublished">Is Published?</el-checkbox>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="form-group col-md-6">
                <label>First Name</label>
                <input
                  v-model.trim="teamMember.firstName"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.teamMember.firstName.$error }"
                />
                <div v-if="submitted && $v.teamMember.firstName.$error" class="invalid-feedback">
                  <span v-if="!$v.teamMember.firstName.required">First name is required</span>
                  <span v-if="!$v.teamMember.firstName.maxLength">First name must be less than 255 characters</span>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Last Name</label>
                <input
                  v-model.trim="teamMember.lastName"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.teamMember.lastName.$error }"
                />
                <div v-if="submitted && $v.teamMember.lastName.$error" class="invalid-feedback">
                  <span v-if="!$v.teamMember.lastName.required">Last name is required</span>
                  <span v-if="!$v.teamMember.lastName.maxLength">Last name must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Url</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">https://eurokick.com/team-member/</span>
                  </div>
                  <input
                    v-model.trim="teamMember.slug"
                    class="form-control"
                    :class="{ 'is-invalid': submitted && $v.teamMember.slug.$error }"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <input type="checkbox" v-model="autoGenerateSlug" class="mr-2" />
                      <span>Auto Generate</span>
                    </span>
                  </div>
                  <div v-if="submitted && $v.teamMember.slug.$error" class="invalid-feedback">
                    <span v-if="!$v.teamMember.slug.required">Slug is required</span>
                    <span v-if="!$v.teamMember.slug.maxLength">Slug must be less than 255 characters</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Tab Title</label>
                <input
                  v-model.trim="teamMember.tabTitle"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.teamMember.tabTitle.$error }"
                />
                <div v-if="submitted && $v.teamMember.tabTitle.$error" class="invalid-feedback">
                  <span v-if="!$v.teamMember.tabTitle.required">Tab title is required</span>
                  <span v-if="!$v.teamMember.tabTitle.maxLength">Tab title must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>About</label>
                <ckeditor
                  v-model="teamMember.about"
                  :editor="editorSettings.editor"
                  :config="editorSettings.config"
                  :class="{ 'is-invalid': submitted && $v.teamMember.about.$error }"
                />
                <div v-if="submitted && $v.teamMember.about.$error" class="invalid-feedback">
                  <span v-if="!$v.teamMember.about.required">About content is required</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label>Office</label>
                <select
                  v-model="teamMember.officeId"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.teamMember.officeId.$error }"
                >
                  <option v-for="office in offices" :key="office.id" :value="office.id">{{ office.name }}</option>
                </select>
                <div v-if="submitted && $v.teamMember.officeId.$error" class="invalid-feedback">
                  <span v-if="!$v.teamMember.officeId.required">Office is required</span>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>LinkedIn</label>
                <input
                  v-model.trim="teamMember.linkedin"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.teamMember.linkedin.$error }"
                />
                <div v-if="submitted && $v.teamMember.linkedin.$error" class="invalid-feedback">
                  <span v-if="!$v.teamMember.linkedin.maxLength">LinkedIn must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label>Twitter</label>
                <input
                  v-model.trim="teamMember.twitter"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.teamMember.twitter.$error }"
                />
                <div v-if="submitted && $v.teamMember.twitter.$error" class="invalid-feedback">
                  <span v-if="!$v.teamMember.twitter.maxLength">Twitter must be less than 255 characters</span>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Instagram</label>
                <input
                  v-model.trim="teamMember.instagram"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.teamMember.instagram.$error }"
                />
                <div v-if="submitted && $v.teamMember.instagram.$error" class="invalid-feedback">
                  <span v-if="!$v.teamMember.instagram.maxLength">Instagram must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Investment Categories</label>
                <el-select
                  v-model="teamMember.investmentCategories"
                  multiple
                  filterable
                  class="w-100"
                  :class="{ 'is-invalid': submitted && $v.teamMember.investmentCategories.$error }"
                >
                  <el-option v-for="category in investmentCategories" :key="category.id" :label="category.name" :value="category.id" />
                </el-select>
                <div v-if="submitted && $v.teamMember.investmentCategories.$error" class="invalid-feedback"></div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Partners</label>
                <el-select
                  v-model="teamMember.partners"
                  multiple
                  filterable
                  class="w-100"
                  :class="{ 'is-invalid': submitted && $v.teamMember.partners.$error }"
                >
                  <el-option v-for="partner in partners" :key="partner.id" :label="partner.name" :value="partner.id" />
                </el-select>
                <div v-if="submitted && $v.teamMember.partners.$error" class="invalid-feedback"></div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Blog Posts</label>
                <el-select
                  v-model="teamMember.blogPosts"
                  multiple
                  filterable
                  class="w-100"
                  :class="{ 'is-invalid': submitted && $v.teamMember.blogPosts.$error }"
                >
                  <el-option v-for="blogPost in blogPosts" :key="blogPost.id" :label="blogPost.title" :value="blogPost.id" />
                </el-select>
                <div v-if="submitted && $v.teamMember.blogPosts.$error" class="invalid-feedback"></div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Redirect To</label>
                <input
                  v-model="teamMember.redirectTo"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.teamMember.redirectTo.$error }"
                />
                <div v-if="submitted && $v.teamMember.redirectTo.$error" class="invalid-feedback">
                  <span v-if="!$v.teamMember.redirectTo.maxLength">Redirect To must be less than 255 characters</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button v-if="isEdit" @click.prevent="handleDelete" type="button" class="btn btn-danger mr-2">Delete</button>
            <button @click.prevent="handlePreview" type="button" class="btn btn-primary mr-2">Preview</button>
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </PageCard>
      <PageCard title="Meta Tags">
        <div class="row mb-3">
          <div class="col-lg-12 text-right">
            <button v-if="teamMember.metaTags.length" type="button" class="btn btn-danger mr-2" @click.prevent="removeAllMetaTags">
              <i class="icon-trash mr-1"></i>
              Remove All
            </button>
            <button type="button" class="btn btn-primary" @click.prevent="addNewMetaTag">
              <i class="icon-plus3 mr-1"></i>
              Add
            </button>
          </div>
        </div>
        <div class="row" v-for="(tag, index) in teamMember.metaTags" :key="index">
          <div class="form-group col-lg-5">
            <label>Name</label>
            <input v-model.trim="tag.name" class="form-control" />
          </div>
          <div class="form-group col-lg-6">
            <label>Content</label>
            <input v-model.trim="tag.content" class="form-control" />
          </div>
          <div class="form-group col-lg-1">
            <label>Actions</label>
            <button type="button" class="btn btn-block btn-danger" @click.prevent="removeMetaTag(index)">
              <i class="icon-trash mr-1"></i>
            </button>
          </div>
        </div>
      </PageCard>
      <PageCard title="Additional JavaScript">
        <textarea
          v-model="teamMember.additionalJavaScript"
          class="form-control"
          :class="{ 'is-invalid': submitted && $v.teamMember.additionalJavaScript.$error }"
        ></textarea>
        <div v-if="submitted && $v.teamMember.additionalJavaScript.$error" class="invalid-feedback"></div>
      </PageCard>
    </form>
    <el-dialog title="Redirect" :visible.sync="showRedirectDialog" width="50%">
      <div class="row">
        <div class="form-group col-12">
          <label>Redirect To</label>
          <input v-model="redirectTo" class="form-control" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button type="button" class="btn btn-warning mr-2" @click="handleRedirect(false)">Delete without Redirect</button>
        <button type="button" class="btn btn-backoffice-primary" @click="handleRedirect(true)" v-if="redirectTo">
          Redirect and Delete
        </button>
      </span>
    </el-dialog>
  </Page>
</template>

<script>
import teamMemberActions from "@/store/teamMember/actions.js";
import { required, maxLength } from "vuelidate/lib/validators";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "dropzone";
import officeActions from "@/store/office/actions.js";
import partnerActions from "@/store/partner/actions.js";
import blogActions from "@/store/blog/actions.js";
import investmentActions from "@/store/investment/actions.js";
import { uploader } from "@/plugins/ckeditorUploadAdapter.js";

let formLoader = null;
let imageDropzoneLoader = null;

export default {
  name: "AddOrUpdateTeamMember",

  data() {
    return {
      teamMember: {
        firstName: "",
        lastName: "",
        slug: "",
        tabTitle: "",
        image: "",
        imageAlt: "",
        about: "",
        linkedin: "",
        twitter: "",
        instagram: "",
        officeId: "",
        partners: [],
        metaTags: [],
        investmentCategories: [],
        isPublished: false,
        redirectTo: "",
        additionalJavaScript: "",
      },

      offices: [],
      partners: [],
      blogPosts: [],
      investmentCategories: [],
      submitted: false,
      autoGenerateSlug: true,
      redirectTo: "",
      showRedirectDialog: false,

      editorSettings: {
        editor: ClassicEditor,
        config: {
          extraPlugins: [uploader],
        },
      },
    };
  },

  async mounted() {
    formLoader = this.$loading.show({ container: this.$refs.form });
    this.offices = await this.$store.dispatch(officeActions.GET_ALL_OFFICES);
    this.partners = await this.$store.dispatch(partnerActions.GET_ALL_PARTNERS);
    this.blogPosts = await this.$store.dispatch(blogActions.GET_ALL_BLOG_POSTS);
    this.investmentCategories = await this.$store.dispatch(investmentActions.GET_ALL_INVESTMENT_CATEGORIES);

    if (this.isEdit) {
      var teamMember = await this.$store.dispatch(teamMemberActions.GET_TEAM_MEMBER_BY_ID, this.$route.params.id);
      teamMember.partners = teamMember.partners.map((partner) => partner.id);
      teamMember.blogPosts = teamMember.blogPosts.map((blogPost) => blogPost.id);
      teamMember.investmentCategories = teamMember.investmentCategories.map((investmentCategory) => investmentCategory.id);
      if (this.$slugify(teamMember.firstName + " " + teamMember.lastName) !== teamMember.slug) {
        this.autoGenerateSlug = false;
      }

      this.redirectTo = teamMember.redirectTo;
      this.teamMember = teamMember;
    }

    this.$nextTick(() => {
      this.initDropzone();
      formLoader.hide();
    });
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        formLoader.hide();
        return;
      }

      await this.$store.dispatch(teamMemberActions.ADD_OR_UPDATE_TEAM_MEMBER, this.teamMember);
      formLoader.hide();
    },

    handleDelete() {
      this.$confirm("Are you sure to delete this record?", () => {
        this.showRedirectDialog = true;
      });
    },

    async handleRedirect(redirect) {
      formLoader = this.$loading.show({ container: this.$refs.form });
      if (redirect) {
        await this.$store.dispatch(teamMemberActions.DELETE_TEAM_MEMBER, {
          id: this.teamMember.id,
          redirectTo: this.redirectTo,
        });
      } else {
        await this.$store.dispatch(teamMemberActions.DELETE_TEAM_MEMBER, {
          id: this.teamMember.id,
        });
      }

      formLoader.hide();
      this.$router.push({ name: "TeamMembers" });
    },

    initDropzone() {
      let imageDropzone = new Dropzone("#imageDropzone", {
        url: `${process.env.VUE_APP_BASE_API_URL}/general/upload-file?uploadType=team-member`,
        maxFiles: 1,
        acceptedFiles: "image/*",
        dictDefaultMessage: "",
        addRemoveLinks: true,
        headers: {
          authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        withCredentials: true,
      });

      imageDropzone.on("addedfile", () => {
        imageDropzoneLoader = this.$loading.show({ container: this.$refs.imageDropzone });
      });

      imageDropzone.on("success", (file, response) => {
        this.teamMember.image = response.data;
      });

      imageDropzone.on("removedfile", () => {
        this.teamMember.image = "";
      });

      imageDropzone.on("complete", () => {
        imageDropzoneLoader.hide();
      });

      if (this.isEdit) {
        const mockFile = { name: "", dataURL: this.teamMember.image, size: 12345, type: "image/jpeg" };

        imageDropzone.emit("addedfile", mockFile);
        imageDropzone.emit("success", mockFile, { data: this.teamMember.image });
        imageDropzone.emit("thumbnail", mockFile, this.teamMember.image);
        imageDropzone.emit("complete", mockFile);
      }
    },

    addNewMetaTag() {
      this.teamMember.metaTags.push({
        name: "",
        content: "",
      });
    },

    removeAllMetaTags() {
      this.teamMember.metaTags = [];
    },

    removeMetaTag(index) {
      this.teamMember.metaTags.splice(index, 1);
    },

    handlePreview() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$message.error("Please fill all required fields");
        return;
      }

      var params = this.teamMember;
      params.page = "founderDetails";
      params.office = this.offices.find((x) => x.id === this.teamMember.officeId).name;
      params.partners = this.partners.filter((x) => this.teamMember.partners.includes(x.id)).map((x) => x.name);
      params.categories = this.investmentCategories.filter((x) => this.teamMember.investmentCategories.includes(x.id)).map((x) => x.name);

      const paramsObj = new URLSearchParams(params).toString();
      window.open(`/preview?${paramsObj.toString()}`, "", "width=1000,height=800");
    },
  },

  computed: {
    isEdit() {
      return this.$router.currentRoute.name === "EditTeamMember";
    },
  },

  watch: {
    "teamMember.firstName": function () {
      if (!this.autoGenerateSlug) {
        return;
      }

      const fullName = `${this.teamMember.firstName} ${this.teamMember.lastName}`;
      this.teamMember.slug = this.$slugify(fullName);
    },
    "teamMember.lastName": function () {
      if (!this.autoGenerateSlug) {
        return;
      }

      const fullName = `${this.teamMember.firstName} ${this.teamMember.lastName}`;
      this.teamMember.slug = this.$slugify(fullName);
    },

    autoGenerateSlug: function () {
      if (this.autoGenerateSlug) {
        const fullName = `${this.teamMember.firstName} ${this.teamMember.lastName}`;
        this.teamMember.slug = this.$slugify(fullName);
      }
    },
  },

  validations() {
    return {
      teamMember: {
        firstName: { required, maxLength: maxLength(255) },
        lastName: { required, maxLength: maxLength(255) },
        slug: { required, maxLength: maxLength(255) },
        tabTitle: { required, maxLength: maxLength(255) },
        image: { required },
        imageAlt: { maxLength: maxLength(255) },
        about: { required },
        linkedin: { maxLength: maxLength(255) },
        twitter: { maxLength: maxLength(255) },
        instagram: { maxLength: maxLength(255) },
        officeId: { required },
        partners: {},
        blogPosts: {},
        investmentCategories: {},
        redirectTo: { maxLength: maxLength(255) },
        additionalJavaScript: {},
      },
    };
  },

  metaInfo() {
    return {
      title: "Add & Update Team Member",
    };
  },
};
</script>
