<template>
  <div class="navbar navbar-expand-lg navbar-light">
    <div class="text-center d-lg-none w-100">
      <button type="button" class="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
        <i class="icon-unfold mr-2"></i>
      </button>
    </div>
    <div class="navbar-collapse collapse" id="navbar-footer">
      <span class="navbar-text">
        Eurokick 2022 © All rights reserved. Developed by
        <a href="https://ronwelldigital.com" target="_blank" class="text-primary">Ronwell Digital</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>
