export const WriterType = {
  Anonymous: {
    value: 0,
    displayName: "Anonymous",
  },
  External: {
    value: 1,
    displayName: "External",
  },
  TeamMember: {
    value: 2,
    displayName: "Team Member",
  },
};

export const ContactType = {
  Investment: {
    value: 0,
    displayName: "Investment",
  },
  Partnership: {
    value: 1,
    displayName: "Partnership",
  },
  Info: {
    value: 2,
    displayName: "Info",
  },
  BePartner: {
    value: 3,
    displayName: "Be Partner",
  },
  Career: {
    value: 4,
    displayName: "Career",
  },
  Other: {
    value: 5,
    displayName: "Other",
  },
};
