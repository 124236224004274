import actionTypes from "./actions.js";
import httpClient from "@/libs/axios.js";

const state = {};

const mutations = {};

const actions = {
  async [actionTypes.GET_HOME_PAGE_CONTENT]() {
    const { data: result } = await httpClient.get("/page-content/home");
    if (result.success) {
      return result.data;
    }
  },
  async [actionTypes.GET_FOUNDERS_PAGE_CONTENT]() {
    const { data: result } = await httpClient.get("/page-content/founders");
    if (result.success) {
      return result.data;
    }
  },
  async [actionTypes.GET_INSIGHTS_PAGE_CONTENT]() {
    const { data: result } = await httpClient.get("/page-content/insights");
    if (result.success) {
      return result.data;
    }
  },
  async [actionTypes.GET_CONTACT_PAGE_CONTENT]() {
    const { data: result } = await httpClient.get("/page-content/contact");
    if (result.success) {
      return result.data;
    }
  },
  async [actionTypes.GET_PARTNERS_PAGE_CONTENT]() {
    const { data: result } = await httpClient.get("/page-content/partners");
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.UPDATE_HOME_PAGE_CONTENT]({ commit }, payload) {
    await httpClient.put("/page-content/home", payload);
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.UPDATE_FOUNDERS_PAGE_CONTENT]({ commit }, payload) {
    await httpClient.put("/page-content/founders", payload);
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.UPDATE_INSIGHTS_PAGE_CONTENT]({ commit }, payload) {
    await httpClient.put("/page-content/insights", payload);
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.UPDATE_CONTACT_PAGE_CONTENT]({ commit }, payload) {
    await httpClient.put("/page-content/contact", payload);
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.UPDATE_PARTNERS_PAGE_CONTENT]({ commit }, payload) {
    await httpClient.put("/page-content/partners", payload);
  },
  async [actionTypes.GET_GLOBAL_META_TAGS]() {
    const { data: result } = await httpClient.get("/page-content/global-meta-tags");
    if (result.success) {
      return result.data;
    }
  },
  async [actionTypes.GET_GLOBAL_SCRIPTS]() {
    const { data: result } = await httpClient.get("/page-content/global-scripts");
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.UPDATE_GLOBAL_META_TAGS]({ commit }, metaTags) {
    await httpClient.put("/page-content/global-meta-tags", { metaTags });
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.UPDATE_GLOBAL_SCRIPTS]({ commit }, payload) {
    await httpClient.put("/page-content/global-scripts", payload);
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
