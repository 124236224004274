export default {
  GET_ALL_BLOG_POSTS: "GET_ALL_BLOG_POSTS",
  GET_BLOG_POST_BY_ID: "GET_BLOG_POST_BY_ID",
  ADD_OR_UPDATE_BLOG_POST: "ADD_OR_UPDATE_BLOG_POST",
  DELETE_BLOG_POST: "DELETE_BLOG_POST",
  GET_ALL_BLOG_POST_CATEGORIES: "GET_ALL_BLOG_POST_CATEGORIES",
  GET_BLOG_POST_CATEGORY_BY_ID: "GET_BLOG_POST_CATEGORY_BY_ID",
  ADD_OR_UPDATE_BLOG_POST_CATEGORY: "ADD_OR_UPDATE_BLOG_POST_CATEGORY",
  DELETE_BLOG_POST_CATEGORY: "DELETE_BLOG_POST_CATEGORY",
  GET_ALL_BLOG_POST_SUBSCRIBERS: "GET_ALL_BLOG_POST_SUBSCRIBERS",
  DELETE_BLOG_POST_SUBSCRIBER: "DELETE_BLOG_POST_SUBSCRIBER",
  GET_ALL_BLOG_POST_WRITERS: "GET_ALL_BLOG_POST_WRITERS",
  GET_BLOG_POST_WRITER_BY_ID: "GET_BLOG_POST_WRITER_BY_ID",
  ADD_OR_UPDATE_BLOG_POST_WRITER: "ADD_OR_UPDATE_BLOG_POST_WRITER",
  DELETE_BLOG_POST_WRITER: "DELETE_BLOG_POST_WRITER",
  GET_HOME_PAGE_BLOG_POSTS: "GET_HOME_PAGE_BLOG_POSTS",
};
