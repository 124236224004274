<template>
  <Page :links="[{ to: 'Offices', text: 'Back to List', iconName: 'icon-arrow-right6' }]">
    <PageCard title="Add & Update Office">
      <form method="post" @submit.prevent="handleSubmit" ref="form">
        <div class="row">
          <div class="form-group col-12">
            <label>Name</label>
            <input v-model.trim="office.name" class="form-control" :class="{ 'is-invalid': submitted && $v.office.name.$error }" />
            <div v-if="submitted && $v.office.name.$error" class="invalid-feedback">
              <span v-if="!$v.office.name.required">Name is required</span>
              <span v-if="!$v.office.name.maxLength">Name must be less than 255 characters</span>
            </div>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button v-if="isEdit" @click.prevent="handleDelete" type="button" class="btn btn-danger mr-2">Delete</button>
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </form>
    </PageCard>
  </Page>
</template>

<script>
import officeActions from "@/store/office/actions.js";
import { required, maxLength } from "vuelidate/lib/validators";

let formLoader = null;

export default {
  name: "AddOrUpdateOffice",

  data() {
    return {
      office: {
        name: "",
      },

      submitted: false,
    };
  },

  async mounted() {
    if (this.isEdit) {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.office = await this.$store.dispatch(officeActions.GET_OFFICE_BY_ID, this.$route.params.id);
      formLoader.hide();
    }
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        formLoader.hide();
        return;
      }

      await this.$store.dispatch(officeActions.ADD_OR_UPDATE_OFFICE, this.office);
      formLoader.hide();
    },

    handleDelete() {
      this.$confirm("Are you sure to delete this record?", async () => {
        formLoader = this.$loading.show({ container: this.$refs.form });
        await this.$store.dispatch(officeActions.DELETE_OFFICE, this.office.id);
        formLoader.hide();
        this.$router.push({ name: "Offices" });
      });
    },
  },

  computed: {
    isEdit() {
      return this.$router.currentRoute.name === "EditOffice";
    },
  },

  validations() {
    return {
      office: {
        name: { required, maxLength: maxLength(255) },
      },
    };
  },

  metaInfo() {
    return {
      title: "Add & Update Office",
    };
  },
};
</script>
