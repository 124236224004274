<template>
  <Page :links="[{ to: 'BlogPosts', text: 'Back to List', iconName: 'icon-arrow-right6' }]">
    <form method="post" @submit.prevent="handleSubmit" ref="form">
      <PageCard title="Add & Update Blog Post">
        <div class="row">
          <div class="col-md-3">
            <div class="row">
              <div class="form-group col-12">
                <label>Card Image</label>
                <div class="dropzone" id="cardImageDropzone" ref="cardImageDropzone"></div>
                <div v-if="submitted && $v.blogPost.cardImage.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPost.cardImage.required">Card image is required</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">{{ blogPost.cardImage }}</div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Card Image Alt</label>
                <input
                  v-model.trim="blogPost.cardImageAlt"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.blogPost.cardImageAlt.$error }"
                />
                <div v-if="submitted && $v.blogPost.cardImageAlt.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPost.cardImageAlt.maxLength">Card image alt must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Thumbnail Image</label>
                <div class="dropzone" id="thumbnailImageDropzone" ref="thumbnailImageDropzone"></div>
                <div v-if="submitted && $v.blogPost.thumbnailImage.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPost.thumbnailImage.required">Thumbnail image is required</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">{{ blogPost.thumbnailImage }}</div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Thumbnail Image Alt</label>
                <input
                  v-model.trim="blogPost.thumbnailImageAlt"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.blogPost.thumbnailImageAlt.$error }"
                />
                <div v-if="submitted && $v.blogPost.thumbnailImageAlt.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPost.thumbnailImageAlt.maxLength">Thumbnail image alt must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>File</label>
                <div class="dropzone" id="fileDropzone" ref="fileDropzone"></div>
                <div v-if="submitted && $v.blogPost.fileUrl.$error" class="invalid-feedback"></div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">{{ blogPost.fileUrl }}</div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>File Name</label>
                <input
                  v-model.trim="blogPost.fileName"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.blogPost.fileName.$error }"
                />
                <div v-if="submitted && $v.blogPost.fileName.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPost.fileName.required">File name is required</span>
                  <span v-if="!$v.blogPost.fileName.maxLength">File name must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <el-checkbox v-model="blogPost.isPublished">Is Published?</el-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <el-checkbox v-model="blogPost.showOnHomepage">Show on Homepage</el-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Reading Time</label>
                <input
                  v-model="blogPost.estimatedReadingTime"
                  type="number"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.blogPost.estimatedReadingTime.$error }"
                />
                <div v-if="submitted && $v.blogPost.estimatedReadingTime.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPost.estimatedReadingTime.minValue">Reading time must be greater than 0</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="form-group col-12">
                <label>Title</label>
                <input
                  v-model.trim="blogPost.title"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.blogPost.title.$error }"
                />
                <div v-if="submitted && $v.blogPost.title.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPost.title.required">Title is required</span>
                  <span v-if="!$v.blogPost.title.maxLength">Title must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Url</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">https://eurokick.com/blog/</span>
                  </div>
                  <input
                    v-model.trim="blogPost.slug"
                    class="form-control"
                    :class="{ 'is-invalid': submitted && $v.blogPost.slug.$error }"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <input type="checkbox" v-model="autoGenerateSlug" class="mr-2" />
                      <span>Auto Generate</span>
                    </span>
                  </div>
                  <div v-if="submitted && $v.blogPost.slug.$error" class="invalid-feedback">
                    <span v-if="!$v.blogPost.slug.required">Slug is required</span>
                    <span v-if="!$v.blogPost.slug.maxLength">Slug must be less than 255 characters</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Tab Title</label>
                <input
                  v-model.trim="blogPost.tabTitle"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.blogPost.tabTitle.$error }"
                />
                <div v-if="submitted && $v.blogPost.tabTitle.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPost.tabTitle.required">Tab title is required</span>
                  <span v-if="!$v.blogPost.tabTitle.maxLength">Tab title must be less than 255 characters</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label>Category</label>
                <select
                  v-model="blogPost.categoryId"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.blogPost.categoryId.$error }"
                >
                  <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
                </select>
                <div v-if="submitted && $v.blogPost.categoryId.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPost.categoryId.required">Category is required</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Content</label>
                <ckeditor
                  v-model="blogPost.content"
                  :editor="editorSettings.editor"
                  :config="editorSettings.config"
                  :class="{ 'is-invalid': submitted && $v.blogPost.content.$error }"
                />
                <div v-if="submitted && $v.blogPost.content.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPost.content.required">Content is required</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label>Writer Type</label>
                <select
                  v-model="blogPost.writerType"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.blogPost.writerType.$error }"
                >
                  <option v-for="type in writerTypes" :key="type.key" :value="type.value">{{ type.displayName }}</option>
                </select>
                <div v-if="submitted && $v.blogPost.writerType.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPost.writerType.required">Writer type is required</span>
                </div>
              </div>
              <div class="form-group col-md-6" v-if="blogPost.writerType === 1">
                <label>Writer</label>
                <select
                  v-model="blogPost.writerId"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.blogPost.writerId.$error }"
                >
                  <option v-for="writer in writers" :key="writer.id" :value="writer.id">
                    {{ writer.firstName }} {{ writer.lastName }}
                  </option>
                </select>
                <div v-if="submitted && $v.blogPost.writerId.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPost.writerId.$error">Writer is required</span>
                </div>
              </div>
              <div class="form-group col-md-6" v-if="blogPost.writerType === 2">
                <label>Writer Team Member</label>
                <select
                  v-model="blogPost.teamMemberId"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.blogPost.teamMemberId.$error }"
                >
                  <option v-for="teamMember in teamMembers" :key="teamMember.id" :value="teamMember.id">
                    {{ teamMember.firstName }} {{ teamMember.lastName }}
                  </option>
                </select>
                <div v-if="submitted && $v.blogPost.teamMemberId.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPost.teamMemberId.required">Team member is required</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Related Posts</label>
                <el-select
                  v-model="blogPost.relatedBlogPosts"
                  multiple
                  filterable
                  class="w-100"
                  :class="{ 'is-invalid': submitted && $v.blogPost.relatedBlogPosts.$error }"
                >
                  <el-option v-for="p in blogPostSelectList" :key="p.id" :label="p.title" :value="p.id" />
                </el-select>
                <div v-if="submitted && $v.blogPost.relatedBlogPosts.$error" class="invalid-feedback"></div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Recommended Posts</label>
                <el-select
                  v-model="blogPost.recommendedBlogPosts"
                  multiple
                  filterable
                  class="w-100"
                  :clear="{ 'is-invalid': submitted && $v.blogPost.recommendedBlogPosts.$error }"
                >
                  <el-option v-for="p in blogPostSelectList" :key="p.id" :label="p.title" :value="p.id" />
                </el-select>
                <div v-if="submitted && $v.blogPost.recommendedBlogPosts.$error" class="invalid-feedback"></div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Redirect To</label>
                <input
                  v-model="blogPost.redirectTo"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.blogPost.redirectTo.$error }"
                />
                <div v-if="submitted && $v.blogPost.redirectTo.$error" class="invalid-feedback">
                  <span v-if="!$v.blogPost.redirectTo.maxLength">Redirect url must be less than 255 characters</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button v-if="isEdit" @click.prevent="handleDelete" type="button" class="btn btn-danger mr-2">Delete</button>
            <button type="button" class="btn btn-primary mr-2" @click="handlePreview">Preview</button>
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </PageCard>
      <PageCard title="Images">
        <div class="row">
          <div class="form-group col-12">
            <label>Browse Files (You can upload multiple files)</label>
            <br />
            <label><b>You can reorder images through drag&drop</b></label>
            <input type="file" @input="imagePreview" multiple />
            <button type="button" class="btn btn-sm btn-danger mt-2" @click.prevent="deleteAllImages" v-if="blogPost.images.length">
              Remove All
            </button>
          </div>
        </div>
        <div ref="imagesPreview">
          <VueDraggable class="row" @end="reorderImages" :animation="150">
            <div
              class="col-lg-2 col-md-3 col-sm-4 mb-3 p-1 preview-image"
              v-for="(image, index) in blogPost.images"
              :key="index"
              :data-index="index"
            >
              <img :src="image.url" class="img-fluid" />
              <button class="btn btn-sm btn-danger" @click.prevent="removeImage(index)">X</button>
              <div class="form-group">
                <label>Alt</label>
                <input v-model.trim="image.alt" class="form-control" />
              </div>
            </div>
          </VueDraggable>
        </div>
      </PageCard>
      <PageCard title="Meta Tags">
        <div class="row mb-3">
          <div class="col-lg-12 text-right">
            <button v-if="blogPost.metaTags.length" type="button" class="btn btn-danger mr-2" @click.prevent="removeAllMetaTags">
              <i class="icon-trash mr-1"></i>
              Remove All
            </button>
            <button type="button" class="btn btn-primary" @click.prevent="addNewMetaTag">
              <i class="icon-plus3 mr-1"></i>
              Add
            </button>
          </div>
        </div>
        <div class="row" v-for="(tag, index) in blogPost.metaTags" :key="index">
          <div class="form-group col-lg-5">
            <label>Name</label>
            <input v-model.trim="tag.name" class="form-control" />
          </div>
          <div class="form-group col-lg-6">
            <label>Content</label>
            <input v-model.trim="tag.content" class="form-control" />
          </div>
          <div class="form-group col-lg-1">
            <label>Actions</label>
            <button type="button" class="btn btn-block btn-danger" @click.prevent="removeMetaTag(index)">
              <i class="icon-trash mr-1"></i>
            </button>
          </div>
        </div>
      </PageCard>
      <PageCard title="Additional JavaScript">
        <textarea
          v-model="blogPost.additionalJavaScript"
          class="form-control"
          :class="{ 'is-invalid': submitted && $v.blogPost.additionalJavaScript.$error }"
        ></textarea>
        <div v-if="submitted && $v.blogPost.additionalJavaScript.$error" class="invalid-feedback"></div>
      </PageCard>
    </form>
    <el-dialog title="Redirect" :visible.sync="showRedirectDialog" width="50%">
      <div class="row">
        <div class="form-group col-12">
          <label>Redirect To</label>
          <input v-model="redirectTo" class="form-control" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button type="button" class="btn btn-warning mr-2" @click="handleRedirect(false)">Delete without Redirect</button>
        <button type="button" class="btn btn-backoffice-primary" @click="handleRedirect(true)" v-if="redirectTo">
          Redirect and Delete
        </button>
      </span>
    </el-dialog>
  </Page>
</template>

<script>
import blogActions from "@/store/blog/actions.js";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "dropzone";
import VueDraggable from "vuedraggable";
import $ from "jquery";
import httpClient from "@/libs/axios.js";
import { required, requiredIf, maxLength, minValue } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import teamMemberActions from "@/store/teamMember/actions.js";
import partnerActions from "@/store/partner/actions.js";
import { uploader } from "@/plugins/ckeditorUploadAdapter.js";

let formLoader = null;
let cardImageDropzoneLoader = null;
let thumbnailImageDropzoneLoader = null;
let imagesLoader = null;
let fileDropzoneLoader = null;

export default {
  name: "AddOrUpdateBlogPost",

  components: {
    VueDraggable,
  },

  data() {
    return {
      blogPost: {
        title: "",
        slug: "",
        tabTitle: "",
        content: "",
        thumbnailImage: "",
        thumbnailImageAlt: "",
        cardImage: "",
        cardImageAlt: "",
        categoryId: null,
        teamMemberId: null,
        images: [],
        writerType: null,
        writerId: null,
        showOnHomepage: false,
        isPublished: false,
        metaTags: [],
        relatedBlogPosts: [],
        recommendedBlogPosts: [],
        redirectTo: "",
        fileUrl: "",
        fileName: "",
        estimatedReadingTime: 0,
        additionalJavaScript: "",
      },

      autoGenerateSlug: true,
      categories: [],
      writers: [],
      teamMembers: [],
      blogPosts: [],
      submitted: false,
      partners: [],
      redirectTo: "",
      showRedirectDialog: false,

      editorSettings: {
        editor: ClassicEditor,
        config: {
          extraPlugins: [uploader],
        },
      },
    };
  },

  async mounted() {
    formLoader = this.$loading.show({ container: this.$refs.form });
    this.categories = await this.$store.dispatch(blogActions.GET_ALL_BLOG_POST_CATEGORIES);
    this.writers = await this.$store.dispatch(blogActions.GET_ALL_BLOG_POST_WRITERS);
    this.teamMembers = await this.$store.dispatch(teamMemberActions.GET_ALL_TEAM_MEMBERS);
    this.blogPosts = await this.$store.dispatch(blogActions.GET_ALL_BLOG_POSTS);
    this.partners = await this.$store.dispatch(partnerActions.GET_ALL_PARTNERS);

    if (this.isEdit) {
      var blogPost = await this.$store.dispatch(blogActions.GET_BLOG_POST_BY_ID, this.$route.params.id);
      blogPost.relatedBlogPosts = blogPost.relatedBlogPosts.map((blogPost) => blogPost.relatedBlogPostId);
      blogPost.recommendedBlogPosts = blogPost.recommendedBlogPosts.map((blogPost) => blogPost.recommendedBlogPostId);

      if (this.$slugify(blogPost.title) !== blogPost.slug) {
        this.autoGenerateSlug = false;
      }

      this.redirectTo = this.blogPost.redirectTo;
      this.blogPost = blogPost;
    }

    this.$nextTick(() => {
      this.initDropzone();
      formLoader.hide();
    });
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        formLoader.hide();
        return;
      }

      await this.$store.dispatch(blogActions.ADD_OR_UPDATE_BLOG_POST, this.blogPost);
      formLoader.hide();
    },

    handleDelete() {
      this.$confirm("Are you sure to delete this record?", () => {
        this.showRedirectDialog = true;
      });
    },

    initDropzone() {
      let cardImageDropzone = new Dropzone("#cardImageDropzone", {
        url: `${process.env.VUE_APP_BASE_API_URL}/general/upload-file?uploadType=blog`,
        maxFiles: 1,
        acceptedFiles: "image/*",
        dictDefaultMessage: "",
        addRemoveLinks: true,
        headers: {
          authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        withCredentials: true,
      });

      cardImageDropzone.on("addedfile", () => {
        cardImageDropzoneLoader = this.$loading.show({ container: this.$refs.cardImageDropzone });
      });

      cardImageDropzone.on("success", (file, response) => {
        this.blogPost.cardImage = response.data;
      });

      cardImageDropzone.on("removedfile", () => {
        this.blogPost.cardImage = "";
      });

      cardImageDropzone.on("complete", () => {
        cardImageDropzoneLoader.hide();
      });

      let thumbnailImageDropzone = new Dropzone("#thumbnailImageDropzone", {
        url: `${process.env.VUE_APP_BASE_API_URL}/general/upload-file?uploadType=blog`,
        maxFiles: 1,
        acceptedFiles: "image/*",
        dictDefaultMessage: "",
        addRemoveLinks: true,
        headers: {
          authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        withCredentials: true,
      });

      thumbnailImageDropzone.on("addedfile", () => {
        thumbnailImageDropzoneLoader = this.$loading.show({ container: this.$refs.thumbnailImageDropzone });
      });

      thumbnailImageDropzone.on("success", (file, response) => {
        this.blogPost.thumbnailImage = response.data;
      });

      thumbnailImageDropzone.on("removedfile", () => {
        this.blogPost.thumbnailImage = "";
      });

      thumbnailImageDropzone.on("complete", () => {
        thumbnailImageDropzoneLoader.hide();
      });

      let fileDropzone = new Dropzone("#fileDropzone", {
        url: `${process.env.VUE_APP_BASE_API_URL}/general/upload-file?uploadType=blog`,
        maxFiles: 1,
        acceptedFiles: "",
        dictDefaultMessage: "",
        addRemoveLinks: true,
        headers: {
          authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        withCredentials: true,
      });

      fileDropzone.on("addedfile", () => {
        fileDropzoneLoader = this.$loading.show({ container: this.$refs.fileDropzone });
      });

      fileDropzone.on("success", (file, response) => {
        this.blogPost.fileUrl = response.data;
      });

      fileDropzone.on("removedfile", () => {
        this.blogPost.fileUrl = "";
      });

      fileDropzone.on("complete", () => {
        fileDropzoneLoader.hide();
      });

      if (this.isEdit) {
        const cardImageMockFile = { name: "", dataURL: this.blogPost.cardImage, size: 12345, type: "image/jpeg" };

        cardImageDropzone.emit("addedfile", cardImageMockFile);
        cardImageDropzone.emit("success", cardImageMockFile, { data: this.blogPost.cardImage });
        cardImageDropzone.emit("thumbnail", cardImageMockFile, this.blogPost.cardImage);
        cardImageDropzone.emit("complete", cardImageMockFile);

        const thumbnailImageMockFile = { name: "", dataURL: this.blogPost.thumbnailImage, size: 12345, type: "image/jpeg" };

        thumbnailImageDropzone.emit("addedfile", thumbnailImageMockFile);
        thumbnailImageDropzone.emit("success", thumbnailImageMockFile, { data: this.blogPost.thumbnailImage });
        thumbnailImageDropzone.emit("thumbnail", thumbnailImageMockFile, this.blogPost.thumbnailImage);
        thumbnailImageDropzone.emit("complete", thumbnailImageMockFile);

        if (this.blogPost.fileUrl) {
          const fileMockFile = { name: "", dataURL: this.blogPost.fileUrl, size: 12345, type: "" };

          fileDropzone.emit("addedfile", fileMockFile);
          fileDropzone.emit("success", fileMockFile, { data: this.blogPost.fileUrl });
          fileDropzone.emit("thumbnail", fileMockFile, this.blogPost.fileUrl);
          fileDropzone.emit("complete", fileMockFile);
        }
      }
    },

    async imagePreview(e) {
      imagesLoader = this.$loading.show({ container: this.$refs.imagesPreview });

      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        var formData = new FormData();
        formData.append("file", file);

        try {
          const { data: result } = await httpClient.post("/general/upload-file?uploadType=blog", formData);
          if (result.success) {
            this.blogPost.images.push({
              url: result.data,
              order: this.blogPost.images.length,
              alt: "",
            });
          }
        } catch {
          this.$message.error("One or more of the images could not be uploaded");
        }
      }

      e.target.value = "";
      imagesLoader.hide();
    },

    deleteAllImages() {
      this.blogPost.images = [];
    },

    reorderImages() {
      $(".preview-image").each((order, el) => {
        const index = $(el).data("index");
        this.blogPost.images[index] = {
          id: this.blogPost.images[index].id,
          order: order,
          url: this.blogPost.images[index].url,
          alt: this.blogPost.images[index].alt,
        };
      });
    },

    removeImage(index) {
      this.blogPost.images.splice(index, 1);
    },

    addNewMetaTag() {
      this.blogPost.metaTags.push({
        name: "",
        content: "",
      });
    },

    removeAllMetaTags() {
      this.blogPost.metaTags = [];
    },

    removeMetaTag(index) {
      this.blogPost.metaTags.splice(index, 1);
    },

    async handleRedirect(redirect) {
      formLoader = this.$loading.show({ container: this.$refs.form });
      if (redirect) {
        await this.$store.dispatch(blogActions.DELETE_BLOG_POST, {
          id: this.blogPost.id,
          redirectTo: this.redirectTo,
        });
      } else {
        await this.$store.dispatch(blogActions.DELETE_BLOG_POST, {
          id: this.blogPost.id,
        });
      }

      formLoader.hide();
      this.$router.push({ name: "BlogPosts" });
    },
    handlePreview() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$message.error("Please fill all required fields");
        return;
      }

      var params = this.blogPost;
      params.page = "blogDetails";
      params.images = params.images.map((img) => img.url);
      const paramsObj = new URLSearchParams(params).toString();
      window.open(`/preview?${paramsObj.toString()}`, "", "width=1000,height=800");
    },
  },

  computed: {
    isEdit() {
      return this.$router.currentRoute.name === "EditBlogPost";
    },
    blogPostSelectList() {
      if (this.isEdit) {
        return this.blogPosts.filter((blogPost) => blogPost.id !== this.blogPost.id);
      }

      return this.blogPosts;
    },
    ...mapGetters(["writerTypes"]),
  },

  watch: {
    "blogPost.title": function () {
      if (!this.autoGenerateSlug) {
        return;
      }

      this.blogPost.slug = this.$slugify(this.blogPost.title);
    },

    "blogPost.estimatedReadingTime": function () {
      if (this.blogPost.estimatedReadingTime === "") {
        this.blogPost.estimatedReadingTime = null;
      }
    },
  },

  validations() {
    return {
      blogPost: {
        title: { required, maxLength: maxLength(255) },
        slug: { required, maxLength: maxLength(255) },
        tabTitle: { required, maxLength: maxLength(255) },
        content: { required },
        thumbnailImage: { required },
        thumbnailImageAlt: { maxLength: maxLength(255) },
        cardImage: { required },
        cardImageAlt: { maxLength: maxLength(255) },
        categoryId: { required },
        writerType: { required },
        teamMemberId: {},
        writerId: {},
        relatedBlogPosts: {},
        recommendedBlogPosts: {},
        redirectTo: { maxLength: maxLength(255) },
        fileUrl: {},
        fileName: {
          required: requiredIf(() => {
            if (this.blogPost.fileUrl) {
              return true;
            }

            return false;
          }),
          maxLength: maxLength(255),
        },
        estimatedReadingTime: { minValue: minValue(1) },
        additionalJavaScript: {},
      },
    };
  },

  metaInfo() {
    return {
      title: "Add & Update Blog Post",
    };
  },
};
</script>

<style scoped>
.preview-image {
  cursor: move;
}
.preview-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}
.preview-image button {
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>
