<template>
  <Page>
    <PageCard title="Change Password">
      <form @submit.prevent="handleSubmit" ref="form">
        <div class="row">
          <div class="form-group col-md-12">
            <label>Current Password</label>
            <input
              type="password"
              v-model="form.currentPassword"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.form.currentPassword.$error }"
            />
            <div v-if="submitted && $v.form.currentPassword.$error" class="invalid-feedback">
              <span v-if="!$v.form.currentPassword.required">Current password is required</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label>New Password</label>
            <input
              type="password"
              v-model="form.newPassword"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.form.newPassword.$error }"
            />
            <div v-if="submitted && $v.form.newPassword.$error" class="invalid-feedback">
              <span v-if="!$v.form.newPassword.required">New password is required</span>
              <span v-if="!$v.form.newPassword.minLength">Password must be 6 characters at least</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label>Confirm Password</label>
            <input
              type="password"
              v-model="form.confirmPassword"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.form.confirmPassword.$error }"
            />
            <div v-if="submitted && $v.form.confirmPassword.$error" class="invalid-feedback">
              <span v-if="!$v.form.confirmPassword.required">Password confirmation is required</span>
              <span v-if="!$v.form.confirmPassword.sameAs">Passwords don't match</span>
            </div>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </form>
    </PageCard>
  </Page>
</template>

<script>
import accountActions from "@/store/account/actions.js";
import { required, minLength, sameAs } from "vuelidate/lib/validators";

let formLoader = null;

export default {
  name: "ChangePassword",

  data() {
    return {
      form: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      submitted: false,
    };
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({
        container: this.$refs.form,
      });
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.$store.dispatch(accountActions.CHANGE_PASSWORD, this.form);
      }

      formLoader.hide();
    },
  },

  validations() {
    return {
      form: {
        currentPassword: { required },
        newPassword: { required, minLength: minLength(6) },
        confirmPassword: { required, sameAs: sameAs("newPassword") },
      },
    };
  },

  metaInfo() {
    return {
      title: "Change Password",
    };
  },
};
</script>
