<template>
  <Page>
    <form @submit.prevent="handleSubmit" ref="form">
      <PageCard title="Update Founders Page Content">
        <div class="row">
          <div class="form-group col-12">
            <label>Tab Title</label>
            <input
              v-model="pageContent.tabTitle"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.tabTitle.$error }"
            />
            <div v-if="submitted && $v.pageContent.tabTitle.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.tabTitle.required">This field is required</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label>Title</label>
            <input
              v-model="pageContent.section1Title"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section1Title.$error }"
            />
            <div v-if="submitted && $v.pageContent.section1Title.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section1Title.required">This field is required</span>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Content</label>
            <input
              v-model="pageContent.section1Content"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.section1Content.$error }"
            />
            <div v-if="submitted && $v.pageContent.section1Content.$error" class="invalid-feedback">
              <span v-if="!$v.pageContent.section1Content.required">This field is required</span>
            </div>
          </div>
        </div>
      </PageCard>
      <PageCard title="Meta Tags">
        <div class="row mb-3">
          <div class="col-lg-12 text-right">
            <button v-if="pageContent.metaTags.length" type="button" class="btn btn-danger mr-2" @click.prevent="removeAllMetaTags">
              <i class="icon-trash mr-1"></i>
              Remove All
            </button>
            <button type="button" class="btn btn-primary" @click.prevent="addNewMetaTag">
              <i class="icon-plus3 mr-1"></i>
              Add
            </button>
          </div>
        </div>

        <div class="row" v-for="(tag, index) in pageContent.metaTags" :key="index">
          <div class="form-group col-lg-5">
            <label>Name</label>
            <input v-model="tag.name" class="form-control" />
          </div>
          <div class="form-group col-lg-6">
            <label>Content</label>
            <input v-model="tag.content" class="form-control" />
          </div>
          <div class="form-group col-lg-1">
            <label>Actions</label>
            <button type="button" class="btn btn-block btn-danger" @click.prevent="removeMetaTag(index)">
              <i class="icon-trash mr-1"></i>
            </button>
          </div>
        </div>
      </PageCard>
      <PageCard title="Additional JavaScript">
        <div class="row">
          <div class="form-group col-12">
            <textarea
              v-model="pageContent.additionalJavascript"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.pageContent.additionalJavascript.$error }"
            ></textarea>
            <div v-if="submitted && $v.pageContent.additionalJavascript.$error" class="invalid-feedback"></div>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button type="button" class="btn btn-primary mr-2" @click="handlePreview">Preview</button>
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </PageCard>
    </form>
  </Page>
</template>

<script>
import pageContentActions from "@/store/pageContent/actions.js";
import { required } from "vuelidate/lib/validators";

let formLoader = null;

export default {
  name: "UpdateFoundersPageContent",

  data() {
    return {
      pageContent: {
        tabTitle: "",
        section1Title: "",
        section1Content: "",
        additionalJavascript: "",
        metaTags: [],
      },

      submitted: false,
    };
  },

  async mounted() {
    formLoader = this.$loading.show({ container: this.$refs.form });
    const { pageContent, metaTags, scripts } = await this.$store.dispatch(pageContentActions.GET_FOUNDERS_PAGE_CONTENT);
    this.pageContent = { ...pageContent, metaTags, additionalJavascript: scripts.content };
    formLoader.hide();
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        formLoader.hide();
        return;
      }

      await this.$store.dispatch(pageContentActions.UPDATE_FOUNDERS_PAGE_CONTENT, this.pageContent);
      formLoader.hide();
    },
    removeAllMetaTags() {
      this.pageContent.metaTags = [];
    },
    addNewMetaTag() {
      this.pageContent.metaTags.push({
        name: "",
        content: "",
      });
    },
    removeMetaTag(index) {
      this.pageContent.metaTags.splice(index, 1);
    },
    handlePreview() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$message.error("Please fill all required fields");
        return;
      }

      var params = this.pageContent;
      params.page = "founders";
      const paramsObj = new URLSearchParams(params).toString();
      window.open(`/preview?${paramsObj.toString()}`, "", "width=1000,height=800");
    },
  },

  validations() {
    return {
      pageContent: {
        tabTitle: { required },
        section1Title: { required },
        section1Content: { required },
        additionalJavascript: {},
      },
    };
  },

  metaInfo() {
    return {
      title: "Update Founders Page Content",
    };
  },
};
</script>
