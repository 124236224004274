import actionTypes from "./actions.js";
import httpClient from "@/libs/axios.js";
import router from "@/router/index.js";

const state = {};

const mutations = {};

const actions = {
  async [actionTypes.GET_ALL_USERS]() {
    const { data: result } = await httpClient.get("/user");
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.GET_USER_BY_ID]({ commit }, id) {
    const { data: result } = await httpClient.get(`/user/${id}`);
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.ADD_OR_UPDATE_USER]({ commit }, user) {
    const { data: result } = await httpClient.post("/user", user);
    if (result.success) {
      router.push({ name: "Users" });
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.DELETE_USER]({ commit }, id) {
    await httpClient.delete(`/user/${id}`);
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
