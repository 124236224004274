import actionTypes from "./actions.js";
import mutationTypes from "./mutations.js";
import httpClient from "@/libs/axios.js";

const state = {
  aboutCompany: {},
};

const mutations = {
  [mutationTypes.SET_ABOUT_COMPANY](state, payload) {
    state.aboutCompany = payload;
  },
};

const actions = {
  async [actionTypes.GET_ABOUT_COMPANY_CONTENT]({ commit }) {
    const { data: result } = await httpClient.get("/company/about");
    if (result.success) {
      commit(mutationTypes.SET_ABOUT_COMPANY, result.data);
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.UPDATE_ABOUT_COMPANY_CONTENT]({ commit }, payload) {
    await httpClient.put("/company/about", payload);
  },
};

const getters = {
  aboutCompany: (state) => state.aboutCompany,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
