<template>
  <Page>
    <form @submit.prevent="handleSubmit" ref="form">
      <PageCard title="Global Meta Tags">
        <div class="row mb-3">
          <div class="col-lg-12 text-right">
            <button type="button" class="btn btn-primary" @click.prevent="addNewMetaTag">
              <i class="icon-plus3 mr-1"></i>
              Add
            </button>
          </div>
        </div>
        <div v-for="(tag, index) in metaTags" :key="index" class="row">
          <div class="form-group col-md-5">
            <label>Name</label>
            <input v-model="tag.name" class="form-control" />
          </div>
          <div class="form-group col-md-6">
            <label>Content</label>
            <input v-model="tag.content" class="form-control" />
          </div>
          <div class="form-group col-md-1">
            <label>Actions</label>
            <button type="button" class="btn btn-block btn-danger" @click.prevent="removeMetaTag(index)">
              <i class="icon-trash mr-1"></i>
            </button>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </PageCard>
    </form>
  </Page>
</template>

<script>
import pageContentActions from "@/store/pageContent/actions.js";

let formLoader = null;

export default {
  name: "EditGlobalMetaTags",

  data() {
    return {
      metaTags: [],
      submitted: false,
    };
  },

  async mounted() {
    formLoader = this.$loading.show({ container: this.$refs.form });
    this.metaTags = await this.$store.dispatch(pageContentActions.GET_GLOBAL_META_TAGS);
    formLoader.hide();
  },

  methods: {
    async handleSubmit() {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.submitted = true;
      await this.$store.dispatch(pageContentActions.UPDATE_GLOBAL_META_TAGS, this.metaTags);
      formLoader.hide();
    },
    addNewMetaTag() {
      this.metaTags.push({
        name: "",
        content: "",
      });
    },
    removeMetaTag(index) {
      this.metaTags.splice(index, 1);
    },

    metaInfo() {
      return {
        title: "Global Meta Tags",
      };
    },
  },
};
</script>
