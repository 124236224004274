<template>
  <Page>
    <form @submit.prevent="handleSubmit" ref="form">
      <PageCard title="Robots.txt">
        <div class="row">
          <div class="form-group col-12">
            <textarea v-model="form.robotsTxt" class="form-control" rows="5"></textarea>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-12">
            <button type="submit" class="btn btn-backoffice-primary">Save</button>
          </div>
        </div>
      </PageCard>
    </form>
  </Page>
</template>

<script>
import seoActions from "@/store/seo/actions.js";

export default {
  name: "RobotsTxt",

  data() {
    return {
      form: {
        robotsTxt: "",
      },
    };
  },

  async mounted() {
    this.form.robotsTxt = await this.$store.dispatch(seoActions.GET_ROBOTS_TXT);
  },

  methods: {
    async handleSubmit() {
      await this.$store.dispatch(seoActions.UPDATE_ROBOTS_TXT, this.form);
    },
  },

  metaInfo() {
    return {
      title: "Edit robots.txt",
    };
  },
};
</script>
