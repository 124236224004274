import axios from "axios";
import Vue from "vue";
import store from "@/store/index.js";
import accountActions from "@/store/account/actions.js";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 5000,
});

httpClient.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("access_token");
  if (accessToken) {
    config.headers.authorization = `Bearer ${accessToken}`;
  }

  return config;
}, undefined);

httpClient.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      Vue.prototype.$message.success(response.data.message);
    }

    return response;
  },
  (error) => {
    switch (error.response.status) {
      case 401:
        store.dispatch(accountActions.LOGOUT);
        break;

      default:
        if (error.response.data.message) {
          Vue.prototype.$message.error(error.response.data.message);
        }
        break;
    }

    return error.response;
  }
);

export default httpClient;
