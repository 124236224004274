import actionTypes from "./actions.js";
import httpClient from "@/libs/axios.js";
import router from "@/router/index.js";

const state = {};

const mutations = {};

const actions = {
  async [actionTypes.GET_ALL_REDIRECTS]() {
    const { data: result } = await httpClient.get("/seo/redirects");
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.GET_REDIRECT_BY_ID]({ commit }, id) {
    const { data: result } = await httpClient.get(`/seo/redirects/${id}`);
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.ADD_OR_UPDATE_REDIRECT]({ commit }, redirect) {
    const { data: result } = await httpClient.post("/seo/redirects", redirect);
    if (result.success) {
      router.push({ name: "Redirects" });
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.DELETE_REDIRECT]({ commit }, id) {
    const { data: result } = await httpClient.delete(`/seo/redirects/${id}`);
    if (result.success) {
      return result.data;
    }
  },
  async [actionTypes.GET_ROBOTS_TXT]() {
    const { data: result } = await httpClient.get("/seo/robots-txt");
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.UPDATE_ROBOTS_TXT]({ commit }, robotsTxt) {
    await httpClient.put("/seo/robots-txt", robotsTxt);
  },
  async [actionTypes.GET_SITEMAP]() {
    const { data: result } = await httpClient.get("/seo/sitemap");
    if (result.success) {
      return result.data;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [actionTypes.UPDATE_SITEMAP]({ commit }, sitemap) {
    const { data: result } = await httpClient.post("/seo/sitemap", sitemap);
    if (result.success) {
      router.push({ name: "Sitemap" });
    }
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
